import React from 'react';

interface PriceProps {
  label: string;
  price: number;
  currency: string;
}

const Price: React.FC<PriceProps> = ({ label, price, currency }) => {
  const formatPrice = (price: number): string => {
    if (price >= 1000) {
      return Math.round(price).toLocaleString();
    } else if (price >= 1) {
      return price.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    } else {
      const formattedPrice = price.toString();
      const parts = formattedPrice.split('.');
      let zeros = 0;
      let nonZeros = '';

      if (parts.length > 1) {
        const matchResult = parts[1].match(/^0*/);
        zeros = matchResult ? matchResult[0].length - 1 : 0;
        nonZeros = parts[1].substring(zeros + 1);
      }

      return `${parts[0]}${parts.length > 1 ? '.' : ''}${zeros > 0 ? '0' : ''}${zeros > 0 ? zeros : ''}${nonZeros}`;
    }
  };

  const formattedPrice = formatPrice(price);

  return (
    <section className="price">
      <h2>
        <label>{label}</label>
        <span className="number">
          <small>$</small>
          {formattedPrice}
        </span>{' '}
        <span className="currency">
          <small>{currency}</small>
        </span>
      </h2>
    </section>
  );
};

export default Price;
