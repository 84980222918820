import React from 'react';
import Tooltip from '@mui/material/Tooltip';

interface MeterProps {
  good: number;
  neutral: number;
  bad: number;
}

function calculatePercentages(good: number, neutral: number, bad: number): { goodPercentage: number, neutralPercentage: number, badPercentage: number } {
  const total = good + neutral + bad;
  const goodPercentage = (good / total) * 100;
  const neutralPercentage = (neutral / total) * 100;
  const badPercentage = (bad / total) * 100;

  return { goodPercentage, neutralPercentage, badPercentage };
}

const Meter: React.FC<MeterProps> = ({ good, neutral, bad }) => {
  const { goodPercentage, neutralPercentage, badPercentage } = calculatePercentages(good, neutral, bad);
  return (
    <div className='meter'>
      {good > 0 &&
        <Tooltip
          classes={{ tooltip: 'tooltip', arrow: 'tooltip-arrow' }}
          placement="top"
          title="POSITIVE SENTIMENT"
          arrow
        >
          <div className="meter-good" style={{ width: goodPercentage + `%` }}>
            <b>
              {`${(goodPercentage).toFixed(0)}%`}{" "}({`${good}`})
            </b>
          
          </div>
        </Tooltip>

      }
      {neutral > 0 &&
        <Tooltip
          classes={{ tooltip: 'tooltip', arrow: 'tooltip-arrow' }}
          placement="top"
          title="NEUTRAL SENTIMENT"
          arrow
        >
          <div className="meter-neutral" style={{ width: neutralPercentage + `%` }}>
            <b>{`${(neutralPercentage).toFixed(0)}%`}
              {" "}({`${neutral}`})
            </b>
          </div>
        </Tooltip>
      }
      {bad > 0 &&
        <Tooltip
          classes={{ tooltip: 'tooltip', arrow: 'tooltip-arrow' }}
          placement="top"
          title="NEGATIVE SENTIMENT"
          arrow
        >
          <div className="meter-bad" style={{ width: badPercentage + `%` }}>
            <b>{`${(badPercentage).toFixed(0)}%`}
              {" "}({`${bad}`})
            </b>
          </div>
        </Tooltip>
      }



    </div>
  );
};

export default Meter;