import React from 'react';
import RollupMicro from '../RollupMicro';
import CustomTooltip from '../CustomTooltip';
import { ListProps } from '../../types/interfaces';

const ListFullscreen: React.FC<ListProps> = ({
  title,
  secondTitle,
  thirdTitle,
  tooltipValue,
  logo,
  logoClass,
  stats,
  spark,
  selected,
  onClick,
}) => {
  return (
    <li onClick={onClick} className={logo || logoClass ? 'logo' : ''}>
      {logo && logo !== '' ? (
        <img id="logo" className="logo" src={logo} alt="" />
      ) : logoClass && logoClass !== '' ? (
        <i id="logo" className={`logo fa-solid ${logoClass}`}></i>
      ) : null}

      <CustomTooltip value={tooltipValue ? tooltipValue : title}>
        <h4>
          {title} <span>{secondTitle}</span>
          <small>{thirdTitle}</small>
        </h4>
      </CustomTooltip>

      <RollupMicro label={stats[0].label} value={stats[0].value} />
    </li>
  );
};

export default ListFullscreen;
