import React, { useState, useEffect } from 'react';
import Panel from '../../components/Panel';
import Details from '../../components/Details';
import Title from '../../components/Title';
import Price from '../../components/Price';
import ChartArea from '../../components/ChartArea';
import Rollup from '../../components/Rollup';
import {
  getLatestWeekDatapoints,
  getLatestMonthDatapoints,
  calculateCorrelationPercent,
} from '../../utilities/statistical-utils';
import { mentionsCoords } from '../../data/testData';
import { componentTranslations } from '../../data/translations';
import { IDataPoint } from '../../types/interfaces';
import { useMyContext } from '../../ContextProvider';

function CoinDay() {
  // get timeframe settings and other context values
  const { timeframeSettings, compactStatus, setCompactStatus } = useMyContext();
  // get the specific timeframe setting for this component
  const timeframe = timeframeSettings[componentTranslations.CoinDay.displayTitle];
  const [correlationPercentage, setCorrelationPercentage] = useState<number | undefined>(undefined);
  const [timeframeAdjustedCoords, setTimeframeAdjustedCoords] = useState<IDataPoint[]>(mentionsCoords);
  const [sumOfY, setSumOfY] = useState(0);
  const latestPrice = mentionsCoords.length > 0 ? mentionsCoords[mentionsCoords.length - 1].y2 : 0;

  const handleCompactStatus = () => {
    setCompactStatus(true);
  };

  const handleExpandStatus = () => {
    setCompactStatus(false);
  };

  useEffect(() => {
    let filteredCoords: IDataPoint[] = [];
    if (timeframe === 'month') {
      filteredCoords = getLatestMonthDatapoints(mentionsCoords);
    } else {
      filteredCoords = getLatestWeekDatapoints(mentionsCoords);
    }

    const yValues = filteredCoords.map((coord) => coord.y);
    const y2Values = filteredCoords.map((coord) => coord.y2);
    if (!y2Values.some((value) => value === undefined)) {
      setCorrelationPercentage(calculateCorrelationPercent(yValues, y2Values as number[]));
    }

    setTimeframeAdjustedCoords(filteredCoords);
    const sumOfY = filteredCoords.reduce((acc, coord) => acc + coord.y, 0);
    setSumOfY(sumOfY);
  }, [timeframe]);

  return (
    <>
      {compactStatus ? (
        <div className="general-compact" onClick={handleExpandStatus}>
          <label>{componentTranslations.CoinDay.displayTitle}</label>
          <div className="general-data-compact">
            <Title
              label={'Coin'}
              effect={true}
              title={'Avalanche'}
              symbol={'AVAX'}
              subtitle=""
              logo="https://upload.wikimedia.org/wikipedia/en/0/03/Avalanche_logo_without_text.png"
            />
          </div>
        </div>
      ) : (
        <Panel title={componentTranslations.CoinDay.displayTitle}>
          <div className="general-data" onClick={handleCompactStatus}>
            <Title
              label={'Coin'}
              effect={true}
              title={'Avalanche'}
              symbol={'AVAX'}
              subtitle=""
              logo="https://upload.wikimedia.org/wikipedia/en/0/03/Avalanche_logo_without_text.png"
            />

            {latestPrice !== undefined && <Price label={'Current Coin Price'} price={latestPrice} currency={'USD'} />}
            <ChartArea
              y1Measuring={'mentions'}
              height={60}
              data={timeframeAdjustedCoords}
              y1Colour={'#50B8E7'}
              marginTop={0}
              headerContent={''}
            />
          </div>

          <div className="general-data-meta">
            <Rollup label="Mentions" value={sumOfY} />
            <Details label="" value={`Avalanche was mentioned ${sumOfY} times in 148 sources analyzed today`} />
          </div>
          {correlationPercentage !== undefined && (
            <div className="general-data-meta">
              <Rollup label="Correlation" value={correlationPercentage} isPercentage={true} />
              <Details
                label=""
                value={`Correlation between price and mentions${correlationPercentage < 0 ? ' where negative values indicate an inverse relation' : ''}`}
              />
            </div>
          )}
        </Panel>
      )}
    </>
  );
}

export default CoinDay;
