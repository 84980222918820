import React from 'react';
import Title from '../../components/Title';
import Price from '../../components/Price';
import ChartArea from '../../components/ChartArea';
import { ListProps } from '../../types/interfaces';
import { noLogoPath } from '../../config';

interface ChartDetailInSimpleProps {
  featuredStatName: string;
  listItem: ListProps | null;
  y1Measuring?: string;
}

const ChartDetailInSimple: React.FC<ChartDetailInSimpleProps> = ({ listItem, featuredStatName, y1Measuring }) => (
  <div className="featured-chart">
    <Title
      label={listItem ? listItem.label : ''}
      effect={true}
      title={listItem ? listItem.title.charAt(0).toUpperCase() + listItem.title.slice(1).toLowerCase() : ''}
      subtitle={listItem ? `${listItem.thirdTitle} chain` : ''}
      symbol={listItem?.secondTitle?.toUpperCase() ?? ''}
      logo={listItem ? listItem.logo : noLogoPath}
    />

    <ChartArea
      y1Measuring={y1Measuring || 'views'}
      height={60}
      data={listItem?.coords || []}
      y1Colour={'#50B8E7'}
      headerContent={<Price label={featuredStatName} price={listItem?.price || 0} currency={'USD'} />}
    />
  </div>
);

export default ChartDetailInSimple;
