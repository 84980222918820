import { IDataPoint } from '../types/interfaces';

export const displayTimestamp = (timestamp: number): string => {
    const date = new Date(timestamp * 1000);
    const formattedDate = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;

    return formattedDate;
}

export const getLatestWeekDatapoints = (data: IDataPoint[]): IDataPoint[] => {
    const oneWeekInSeconds = 7 * 24 * 60 * 60;
    if (data.length === 0) {
        throw new Error('Invalid data');
    }
    const lastTimestamp = data[data.length - 1].x;
    const oneWeekAgoTimestamp = lastTimestamp - oneWeekInSeconds;

    let filteredCoords = [];
    filteredCoords = data.filter((datapoint) => typeof datapoint.x === 'number' && datapoint.x >= oneWeekAgoTimestamp);

    return filteredCoords;
}

export const getLatestMonthDatapoints = (data: IDataPoint[]): IDataPoint[] => {
    const oneMonthInSeconds = 30 * 24 * 60 * 60;
    if (data.length === 0) {
        throw new Error('Invalid data');
    }
    const lastTimestamp = data[data.length - 1].x;
    const oneMonthAgoTimestamp = lastTimestamp - oneMonthInSeconds;

    let filteredCoords = [];
    filteredCoords = data.filter((datapoint) => typeof datapoint.x === 'number' && datapoint.x >= oneMonthAgoTimestamp);

    return filteredCoords;
}

export const calculateCorrelationPercent = (data1: number[], data2: number[]): number => {
    const n = data1.length;
    let sumY1 = 0;
    let sumY2 = 0;
    let sumY2Y1 = 0;
    let sumY1Square = 0;
    let sumY2Square = 0;

    for (let i = 0; i < n; i++) {
        sumY1 += data1[i];
        sumY2 += data2[i];
        sumY2Y1 += data2[i] * data1[i];
        sumY1Square += Math.pow(data1[i], 2);
        sumY2Square += Math.pow(data2[i], 2);
    }

    const numerator = n * sumY2Y1 - sumY2 * sumY1;
    const denominator = Math.sqrt((n * sumY2Square - Math.pow(sumY2, 2)) * (n * sumY1Square - Math.pow(sumY1, 2)));

    const correlationCoefficient = numerator / denominator;
    const percentage = Math.round(correlationCoefficient * 100);
    return percentage;
};