import React from 'react';
import Title from '../../components/Title';
import ChartArea from '../../components/ChartArea';
import FeatureStat from '../../components/FeatureStat';
import { noLogoPath } from '../../config';
import { ListProps } from '../../types/interfaces';

interface ChartDetailedInStackedProps {
  listItem: ListProps | null;
  y1Measuring?: string;
}

const ChartDetailedInStacked: React.FC<ChartDetailedInStackedProps> = ({ listItem, y1Measuring }) => (
  <>
    <Title
      label={listItem ? listItem.label : ''}
      effect={true}
      title={listItem ? `${listItem.thirdTitle}` : ''}
      tooltip={listItem ? `${listItem.tooltipValue}` : ''}
      logo={listItem ? listItem.logo : noLogoPath}
      logoClass={listItem ? listItem.logoClass : ''}
      bottomBumper={true}
    />
    <ChartArea
      y1Measuring={y1Measuring || 'views'}
      height={72}
      data={listItem?.coords || []}
      y1Colour={'#50B8E7'}
      marginTop={20}
      headerContent={
        <FeatureStat
          value={parseFloat(listItem?.stats[0]?.value.toString() || '')}
          valueChange={parseFloat(listItem?.stats[0]?.change?.toString() || '')}
          subtitle={listItem?.stats[0]?.label.toString() || ''}
          top={'-25px'}
          disableMoreSuffix={true}
        />
      }
    />
  </>
);

export default ChartDetailedInStacked;
