import React from 'react';
import PrettyNumber from '../../components/PrettyNumber';
import NumberChange from '../../components/NumberChange';

interface RollupMacroProps {
  label: string;
  value: number;
  valueChange?: number;
}

const RollupMacro: React.FC<RollupMacroProps> = ({ label, value, valueChange }) => {
  return (
    <div className="rollup-macro">
      <div className="rollup-detail">
        <span className="number">
          <PrettyNumber value={value} disableMoreSuffix={true} />
          {!!valueChange && (
            <span className="number-change">
              <NumberChange value={valueChange} isArrow={true} />
            </span>
          )}
        </span>
        <span className="label">{label}</span>
      </div>
    </div>
  );
};

export default RollupMacro;
