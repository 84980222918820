import React, { useState } from 'react';
import Drawer from '@mui/material/Drawer';
import Offer from '../../components/Offer';
import { useMyContext } from '../../ContextProvider';

interface NavProps {
  value: string;
}

const Nav: React.FC<NavProps> = ({ value }) => {
  const { moduleBuzzwords, toggleModule } = useMyContext();

  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  function handleBuzzwords() {
    toggleModule('moduleBuzzwords');
    localStorage.setItem('moduleBuzzwords', String(!moduleBuzzwords));
  }

  type Anchor = 'top' | 'left' | 'bottom' | 'right';

  const toggleDrawer = (anchor: Anchor, newOpen: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, [anchor]: newOpen });
  };


  return (
    <>
      <div className="nav">
        <div className="nav-left">
          <img src="img/logo.png" alt="" />
        </div>
        <div className="nav-right">
          <span>
            <small>DEV NOTE</small><br/>
            {process.env.REACT_APP_USE_REMOTE_API === 'true' ? `${process.env.REACT_APP_REMOTE_API}:${process.env.REACT_APP_SERVER_PORT}` : `${process.env.REACT_APP_LOCAL_API}:${process.env.REACT_APP_SERVER_PORT}`}{' '}
          </span>
          {!moduleBuzzwords && (
            <button onClick={handleBuzzwords} className="module-icon">
              <i className="fa-sharp fa-solid fa-grid-2-plus"></i>
            </button>
          )}
        </div>
      </div>

      <Drawer anchor="right" id="premium" open={state.right} onClose={toggleDrawer('right', false)}>
        <button className="exit" onClick={toggleDrawer('right', false)}>
          <i className="fa-sharp fa-regular fa-xmark"></i>
        </button>
        <Offer />
      </Drawer>
    </>
  );
};

export default Nav;
