import React, { useState, useEffect, useMemo } from 'react';
import { categoriesArray } from '../../data/testData';
import FeatureWithInteractiveList from '../../components/FeatureWithInteractiveList';
import List from '../../components/List';
import Rollup from '../../components/Rollup';
import ChartArea from '../../components/ChartArea';
import ChartDetailedInStacked from '../../components/ChartDetailedInStacked';
import { noLogoPath } from '../../config';
import { noLogoClass } from '../../config';
import { ListProps } from '../../types/interfaces';

function CategoriesTop({ premium = false }: { premium?: boolean }) {
  const [selectedCategory, setSelectedCategory] = useState<ListProps | null>(null);
  const listLimit = 5;

  const createListItem = (category: ListProps, onClick?: () => void): ListProps => ({
    ...category,
    onClick: onClick || (() => {}),
  });
  const listItems: ListProps[] = useMemo(
    () =>
      categoriesArray.slice(0, listLimit).map((category) => {
        const updatedCategory = {
          ...category,
          tooltipValue: category.title,
          title: category.title.replace(/decentralized/gi, 'D.'),
          thirdTitle: category.thirdTitle ? category.thirdTitle.replace(/decentralized/gi, 'dec.') : undefined,
        };

        return createListItem(updatedCategory, () => {
          setSelectedCategory(updatedCategory);
        });
      }),
    [],
  );

  useEffect(() => {
    if (listItems.length > 0) {
      setSelectedCategory(listItems[0]);
    }
  }, [listItems]);

  const renderListItem = (item: ListProps, index: number) => (
    <List
      key={index}
      {...item}
      title={item.thirdTitle ? item.thirdTitle : ''}
      thirdTitle={item.title}
      logo={item.logo ? item.logo : ''}
      logoClass={item.logoClass ? item.logoClass : noLogoClass}
      stats={item.stats.slice(0, 2)}
      spark={item.spark ? item.spark : []}
    />
  );

  return (
    <FeatureWithInteractiveList
      premium={premium}
      title="Top Categories"
      listItems={listItems}
      selectedItem={selectedCategory}
      setSelectedItem={setSelectedCategory}
      FeaturedComponent={ChartDetailedInStacked}
      featuredStatName="Average Views"
      renderListItem={renderListItem}
      renderLeftDrawerContent={() => (
        <>
          <ChartArea
            y1Measuring={'views'}
            height={200}
            data={categoriesArray[0].coords}
            y1Colour={'#50B8E7'}
            marginTop={20}
            headerContent={''}
            isMicroTooltip={true}
          />

          <div>
            <Rollup label="Mentions" value={99} />
            <Rollup label="Views" value={3000000} />
            <Rollup label="Channels" value={3} />
          </div>
        </>
      )}
      renderRightDrawerContent={() => (
        <div className="list">{categoriesArray.map((category, index) => renderListItem(createListItem(category), index))}</div>
      )}
    />
  );
}

export default CategoriesTop;
