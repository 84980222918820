
import React from 'react';

interface DetailsProps {
  label: string;
  value: string | number;
}

const Details: React.FC<DetailsProps> = ({ label, value }) => {
  return (
    <div className="details">
      <label>{label}</label>
      <p>{value}</p>
    </div>
  );
};

export default Details;
