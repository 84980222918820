import React from 'react';
import Core from "./pages/Core";
import "./styles/index.scss";
import { MyContextProvider } from './ContextProvider';


const App: React.FC = () => {
  return (
    <MyContextProvider>
    <Core />
  </MyContextProvider>
  );
};

export default App;
