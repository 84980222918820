import React, { useEffect, useState } from 'react';
import { ISentimentDataPoint } from '../../types/interfaces';
import Currency from '../../components/Currency';
import { Bar, Cell, XAxis, YAxis, Tooltip, ResponsiveContainer, ComposedChart, Line } from 'recharts';
import { displayTimestamp } from '../../utilities/statistical-utils';

interface SentimentRating {
  name: number;
  value: number;
  price: number | undefined;
}

const postiveValueColor = 'rgba(0,200,159, 1)';
const negativeValueColor = 'rgba(244,89,89, 1)';
const priceLineColour = 'rgba(80, 184, 231, .84)';

const CustomTooltip = ({ active, payload, label, displayPrice }: any) => {
  if (active && payload && payload.length) {
    const formattedDate = displayTimestamp(label);
    const valueClass = payload[0].value > 0 ? 'positive-value' : 'negative-value';
    const displayValue = payload[0].value > 0 ? `+${payload[0].value}` : payload[0].value;

    return (
      <div className="custom-tooltip">
        <p className="label">
          <span className={`sentiment-value ${valueClass}`}>{displayValue}</span>
          {displayPrice && !!payload[0].payload.price && (
            <span className="price">
              <Currency value={payload[0].payload.price} />
            </span>
          )}
          <span className="date">{`${formattedDate}`}</span>
        </p>
      </div>
    );
  }

  return null;
};

const ChartPosNegBar: React.FC<{ sentimentCoords: ISentimentDataPoint[]; displayPrice?: boolean }> = ({
  sentimentCoords,
  displayPrice = true,
}) => {
  const [sentimentRating, setSentimentRating] = useState<SentimentRating[]>([]);
  const [hasPrice, setHasPrice] = useState<boolean>(false);

  useEffect(() => {
    const calculatedValues = sentimentCoords.map((dataPoint) => {
      return {
        name: dataPoint.x,
        value: dataPoint.positive - dataPoint.mixed / 2 - (dataPoint.negative - dataPoint.mixed / 2),
        price: dataPoint.price,
      };
    });

    setSentimentRating(calculatedValues);
    setHasPrice(sentimentCoords.every((dataPoint) => dataPoint.price !== undefined));
  }, [sentimentCoords]);

  return (
    <div className="chart-posnegbar">
      <ResponsiveContainer width="100%" height={100}>
        <ComposedChart
          data={sentimentRating}
          margin={{
            top: 5,
            right: 0,
            left: 0,
            bottom: 5,
          }}
        >
          <XAxis hide={true} dataKey="name" />
          <YAxis yAxisId="left" hide={true} domain={['dataMin', 'dataMax']} />
          {displayPrice && hasPrice && <YAxis yAxisId="right" orientation="right" hide={true} domain={['auto', 'auto']} />}
          <Tooltip
            content={<CustomTooltip displayPrice={displayPrice} />}
            cursor={{ stroke: priceLineColour, strokeDasharray: 2, fillOpacity: 0 }}
          />
          <Bar yAxisId="left" dataKey="value">
            {sentimentRating.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.value > 0 ? postiveValueColor : negativeValueColor} />
            ))}
          </Bar>
          {displayPrice && hasPrice && (
            <Line type="linear" yAxisId="right" dataKey="price" strokeWidth={2} stroke={priceLineColour} fillOpacity={0} dot={false} />
          )}
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};

export default ChartPosNegBar;
