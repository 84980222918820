import React, { ReactNode } from 'react';
import { AreaChart, Tooltip, Area, ResponsiveContainer, TooltipProps, XAxis, YAxis } from 'recharts';
import Currency from '../../components/Currency';
import { displayTimestamp } from '../../utilities/statistical-utils';

const priceLineColour = '#8884d8';

interface ChartAreaProps {
  height: number;
  marginTop?: number;
  y1Measuring: string;
  y1Colour?: string; // hex
  y2Colour?: string; // hex
  dots?: boolean;
  data: {
    x: number; // timestamp
    y: number;
    y2?: number;
  }[];
  headerContent?: ReactNode;
  isMicroTooltip?: boolean;
}

interface Payload {
  y: number;
  y2: number;
}

interface CustomTooltipProps extends TooltipProps<number, string> {
  isMicroTooltip?: boolean;
  y1Measuring: string;
  y1Colour?: string;
  y2Colour?: string;
}

const CustomTooltip: React.FC<CustomTooltipProps> = ({
  active,
  payload,
  label,
  isMicroTooltip,
  y1Measuring,
  y1Colour,
  y2Colour = priceLineColour,
}) => {
  if (active && payload && payload.length) {
    const formattedDate = displayTimestamp(label);

    return (
      <div className={`custom-tooltip ${isMicroTooltip ? 'micro-tooltip' : 'normal-tooltip'}`}>
        <div className="intro">
          <span className="color-box" style={{ backgroundColor: y1Colour }}></span>
          {`${(payload[0].payload as Payload).y} ${y1Measuring} on ${formattedDate}`}
        </div>
        {payload[0].payload.y2 !== undefined && (
          <div className="y2">
            <span className="color-box" style={{ backgroundColor: y2Colour }}></span>
            <span>{`when price was `}</span>
            <Currency value={(payload[0].payload as Payload).y2} />
          </div>
        )}
      </div>
    );
  }

  return null;
};

const ChartArea: React.FC<ChartAreaProps> = ({
  data,
  height,
  y1Colour,
  marginTop,
  headerContent,
  isMicroTooltip,
  y1Measuring,
  dots,
  y2Colour = priceLineColour,
}) => {
  const hasY2 = data.some((item) => 'y2' in item);
  const strokeWidth = 1;

  return (
    <div className="chart-area" style={{ marginTop: marginTop ? marginTop : 0 }}>
      <div className="chart-area-price" style={{ zIndex: 2 }}>
        {headerContent && headerContent}
      </div>
      <ResponsiveContainer width="100%" height={height}>
        <AreaChart data={data} margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
          <defs>
            <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor={y1Colour} stopOpacity={0.8} />
              <stop offset="95%" stopColor={y1Colour} stopOpacity={0} />
            </linearGradient>
          </defs>
          <XAxis dataKey="x" hide={true} domain={['dataMin', 'dataMax']} />
          <YAxis yAxisId="left" hide={true} domain={['dataMin', 'dataMax']} />
          {hasY2 && <YAxis yAxisId="right" orientation="right" hide={true} domain={['auto', 'auto']} />}
          <Tooltip
            content={
              <CustomTooltip
                isMicroTooltip={isMicroTooltip}
                y1Measuring={y1Measuring}
                y1Colour={y1Colour}
                y2Colour={y2Colour}
              />
            }
            cursor={{ stroke: y1Colour, strokeDasharray: 2, fillOpacity: 0 }}
          />
          <Area
            type="linear"
            dataKey="y"
            stroke={y1Colour}
            strokeWidth={strokeWidth} // Add this line
            fillOpacity={1}
            fill="url(#colorPv)"
            yAxisId="left"
            dot={dots ? { stroke: 'white', strokeWidth: 1, r: 3, strokeDasharray: '' } : { r: 0 }}
          />{' '}
          {hasY2 && (
            <Area type="linear" dataKey="y2" stroke={y2Colour} strokeWidth={strokeWidth} fillOpacity={0} yAxisId="right" />
          )}
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

export default ChartArea;
