import React, { useState } from 'react';

function Promotion() {
  const [promotionStatus, setPromotionStatus] = useState<boolean>(false);

  function handlePromotion() {
    localStorage.setItem("promotionStatus", "off");
    setPromotionStatus(false);
    window.location.reload(); 
  }


  if (promotionStatus) {
    return null;
  }

  return (
    <div className="promotion-container fade-in">
      <div className="offer-feature-promotion">
        <header>
          <a href="https://www.shopify.com" target="_blank" rel="noreferrer">
            <i className={`fa-sharp fa-solid fa-square-bolt premium`}></i>
            <h1>Unlock Premium Features!</h1>
            <p>Get historical data, download statistics,<br/>customize your dashboard<strong>...and more</strong></p>
          </a>
        </header>
        <button onClick={handlePromotion}>No Thanks</button>
      </div>
    </div>
  );
}

export default Promotion;