import React, { useEffect, createContext, useContext, useState, ReactNode, useCallback } from 'react';
import { Timeframe, TimeframeSettings } from './types/interfaces';
import { componentTranslations } from './data/translations';

interface DefaultContextData {
  moduleBuzzwords: boolean;
  compactStatus: boolean;
  expandedStatus: string;
  updateContext: (moduleBuzzwords: boolean) => void;
  toggleModule: (moduleName: keyof DefaultContextData) => void;
  timeframeSettings: TimeframeSettings;
  updateTimeframeSettings: (componentName: string, timeframe: Timeframe) => void;
  setCompactStatus: React.Dispatch<React.SetStateAction<boolean>>; // New addition
}

const MyContext = createContext<DefaultContextData>({
  moduleBuzzwords: true,
  compactStatus: false,
  expandedStatus: '',
  updateContext: () => {},
  toggleModule: () => {},
  timeframeSettings: {},
  updateTimeframeSettings: () => {},
  setCompactStatus: () => {}, // Initial empty function
});

interface MyContextProviderProps {
  children: ReactNode;
}

// helper function to get an object from localstorage
function getLocalStorageObject<T>(key: string, defaultValue: T): T {
  const storedValue = localStorage.getItem(key);
  return storedValue ? JSON.parse(storedValue) : defaultValue;
}

export const MyContextProvider: React.FC<MyContextProviderProps> = ({ children }) => {
  const [moduleBuzzwords, setModuleBuzzwords] = useState(() => getLocalStorageBoolean('moduleBuzzwords', false));
  const [compactStatus, setCompactStatus] = useState(() => getLocalStorageBoolean('compactStatus', false));
  const [expandedStatus, setExpandedStatus] = useState(() => '');
  // initialize timeframe settings for each component
  // each component has its own timeframe setting (e.g., 'week', 'month')
  const [timeframeSettings, setTimeframeSettings] = useState<TimeframeSettings>(() =>
    getLocalStorageObject('timeframeSettings', {
      [componentTranslations.CoinDay.displayTitle]: 'week',
      [componentTranslations.Buzzwords.displayTitle]: 'week',
      // To add a new component with timeframe settings:
      // 1. Add a new entry here with the component's display title and default timeframe
      // [componentTranslations.NewComponent.displayTitle]: 'week',
      //
      // IMPORTANT: When adding a new component, you also need to:
      // 2. Update src/data/translations.ts:
      //    Add a new entry for your component in the componentTranslations object
      // 3. Create a new component file (e.g., src/modules/NewComponent/index.tsx):
      //    - Import useMyContext and use it to access timeframeSettings
      //    - Use the timeframe value in your component logic
      // 4. Update src/pages/Core/index.tsx:
      //    Import and add your new component to the layout
      //
      // For detailed instructions, refer to the comments in each of these files.
    }),
  );

  const updateContext = useCallback((moduleBuzzwords: boolean) => {
    setModuleBuzzwords(moduleBuzzwords);
    setCompactStatus(compactStatus);
    setExpandedStatus(expandedStatus);
  }, []);

  const toggleModule = useCallback(
    (moduleName: keyof DefaultContextData) => {
      updateContext(moduleName === 'moduleBuzzwords' ? !moduleBuzzwords : moduleBuzzwords);
    },
    [moduleBuzzwords, updateContext],
  );

  // update timeframe setting for a specific component
  // this function allows each component to have its own independent timeframe
  const updateTimeframeSettings = useCallback((componentName: string, timeframe: Timeframe) => {
    setTimeframeSettings((prevSettings) => {
      const newSettings = {
        ...prevSettings,
        [componentName]: timeframe,
      };
      localStorage.setItem('timeframeSettings', JSON.stringify(newSettings));
      return newSettings;
    });
  }, []);

  const contextValues: DefaultContextData = {
    moduleBuzzwords,
    compactStatus,
    expandedStatus,
    updateContext,
    toggleModule,
    timeframeSettings,
    updateTimeframeSettings,
    setCompactStatus, // Exposing the setCompactStatus function
  };

  return <MyContext.Provider value={contextValues}>{children}</MyContext.Provider>;
};

export const useMyContext = () => {
  const context = useContext(MyContext);
  if (!context) {
    throw new Error('useMyContext must be used within a MyContextProvider');
  }
  return context;
};

function getLocalStorageBoolean(key: string, defaultValue: boolean): boolean {
  const storedValue = localStorage.getItem(key);
  return storedValue === 'true' || storedValue === 'false' ? storedValue === 'true' : defaultValue;
}
