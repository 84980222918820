import React from 'react';
import PrettyNumber from '../../components/PrettyNumber';
import NumberChange from '../../components/NumberChange';

interface FeatureStatProps {
  subtitle: string;
  top?: string;
  value: number;
  valueChange?: number;
  disableMoreSuffix?: boolean;
  align?: 'left' | 'right';
}

const FeatureStat: React.FC<FeatureStatProps> = ({ subtitle, top, value, align, valueChange, disableMoreSuffix }) => {
  return (
    <div className="feature-stat">
      <div
        className="stat-container"
        style={{
          top: top ? top : undefined,
          left: align === 'right' ? undefined : '0',
          right: align === 'right' ? '0' : undefined,
        }}
      >
        <div className="stat-right">
          <span className="number">
            <PrettyNumber value={value} disableMoreSuffix={disableMoreSuffix} />
            {!!valueChange && (
              <span className="number-change">
                <NumberChange value={valueChange} isArrow={true} />
              </span>
            )}
          </span>
          <span className="subtitle">{subtitle}</span>
        </div>
      </div>
    </div>
  );
};

export default FeatureStat;
