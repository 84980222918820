import OfferFeature from './OfferFeature';

const offerArray = [
  {
    icon: 'premium',
    title: 'Premium',
    details: ["historical data", "downloadable statistics", "custom dashboard"],
    rate: '$8/Month',
    intro: true,
    path: 'http://shopify.com',
  },
  {
    icon: 'beast',
    title: 'Beast',
    details: ["all our premium features", "plus...", "run our engine on your own data"],
    rate: '$80/Month',
    intro: false,
    path: 'http://shopify.com',
  },
];

function Offer() {
  return (
    <>
      {offerArray.map((item, index) => (
        <OfferFeature
          key={index}
          offerIcon={item.icon}
          offerTitle={item.title}
          offerDetails={item.details}
          offerRate={item.rate}
          offerIntro={item.intro}
          linkPath={item.path}
        />
      ))}
    </>
  );
}

export default Offer;
