import React from 'react';

interface TruncateStringProps {
  text: string | undefined;
  emptyText?: string;
  maxLength: number;
}

const TruncateString: React.FC<TruncateStringProps> = ({ text, maxLength, emptyText = '' }) => {
  return <>{text && text.length > maxLength ? text.slice(0, maxLength) + '...' : text ?? emptyText}</>;
};

export default TruncateString;
