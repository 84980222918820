import React from 'react';
import CountUp from 'react-countup';

interface NumberChangeProps {
  value: number;
  isArrow?: boolean;
}

const NumberChange: React.FC<NumberChangeProps> = ({ value, isArrow }) => (
  <div className="number-change">
    <div className={value > 0 ? 'up' : 'down'}>
      {isArrow ? (
        <i className={`fa-sharp fa-solid ${value > 0 ? 'fa-arrow-up-long' : 'fa-arrow-down-long'} fade-delay-long`}></i>
      ) : (
        <>{value >= 0 ? '+' : ''}</>
      )}
      <CountUp
        start={0}
        end={!!isArrow ? Math.abs(value) : value}
        duration={0.6}
        useEasing={true}
        delay={0.3}
        decimals={0}
        className="fade-delay-short"
      />
      <span className="fade-delay-long">%</span>
    </div>
  </div>
);

export default NumberChange;
