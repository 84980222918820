import React from 'react';

interface OfferFeatureProps {
  offerIcon: string;
  offerTitle: string;
  offerDetails: string[];
  offerRate: string;
  offerIntro: boolean;
  linkPath: string;
}

const OfferFeature: React.FC<OfferFeatureProps> = ({ offerIcon, offerTitle, offerDetails, offerRate, offerIntro, linkPath }) => {
  return (
    <div className="offer-feature">
      <header>
        <a href={linkPath} target="_blank" rel="noreferrer">
          <i className={`fa-sharp fa-solid fa-square-bolt ` + offerIcon}></i>
          <h1>{offerTitle}</h1>
          <h2>Access</h2>
          <div>
          {offerDetails.map((item, index) => (
            <span key={index}>{item}</span>
            
            ))}
            </div>
          <h3 className={offerIcon}>{offerRate}</h3>
          {offerIntro && <h4>INTRODUCTORY RATE</h4>}
        </a>
       

      </header>

    </div>
  );
};

export default OfferFeature;
