import { ISentimentDataPoint, IDataPoint } from '../types/interfaces';

function multiplyByRandomNumber(chainCoords: { x: number; y: number }[]) {
  return chainCoords.map(coord => ({
    ...coord,
    y: Math.floor(coord.y * (Math.random() * (100 - 1) + 1)),
  }));
}

export const sentimentCoords: ISentimentDataPoint[] = [
  { x: 1672444800, positive: 50, negative: 30, mixed: 20, price: 0.5 }, // Date: 2023-01-01
  { x: 1672531200, positive: 40, negative: 40, mixed: 20, price: 0.53 }, // Date: 2023-01-02
  { x: 1672617600, positive: 55, negative: 35, mixed: 10, price: 0.48 }, // Date: 2023-01-03
  { x: 1672704000, positive: 65, negative: 20, mixed: 15, price: 0.51 }, // Date: 2023-01-04
  { x: 1672790400, positive: 45, negative: 30, mixed: 25, price: 0.55 }, // Date: 2023-01-05
  { x: 1672876800, positive: 60, negative: 30, mixed: 10, price: 0.4 }, // Date: 2023-01-06
  { x: 1672963200, positive: 35, negative: 45, mixed: 20, price: 0.52 }, // Date: 2023-01-07
  { x: 1673049600, positive: 75, negative: 20, mixed: 5, price: 0.6 }, // Date: 2023-01-08
  { x: 1673136000, positive: 55, negative: 25, mixed: 20, price: 0.65 }, // Date: 2023-01-09
  { x: 1673222400, positive: 60, negative: 35, mixed: 5, price: 0.83 }, // Date: 2023-01-10
  { x: 1673308800, positive: 70, negative: 30, mixed: 0, price: 0.67 }, // Date: 2023-01-11
  { x: 1673395200, positive: 45, negative: 50, mixed: 5, price: 0.71 }, // Date: 2023-01-12
  { x: 1673481600, positive: 65, negative: 25, mixed: 10, price: 0.68 }, // Date: 2023-01-13
  { x: 1673568000, positive: 30, negative: 55, mixed: 15, price: 0.72 }, // Date: 2023-01-14
  { x: 1673654400, positive: 80, negative: 15, mixed: 5, price: 0.76 }, // Date: 2023-01-15
  { x: 1673740800, positive: 50, negative: 35, mixed: 15, price: 0.73 }, // Date: 2023-01-16
  { x: 1673827200, positive: 55, negative: 25, mixed: 20, price: 0.78 }, // Date: 2023-01-17
  { x: 1673913600, positive: 60, negative: 30, mixed: 10, price: 0.81 }, // Date: 2023-01-18
  { x: 1674000000, positive: 40, negative: 50, mixed: 10, price: 0.79 }, // Date: 2023-01-19
  { x: 1674086400, positive: 70, negative: 25, mixed: 5, price: 0.63 }, // Date: 2023-01-20
  { x: 1674172800, positive: 35, negative: 40, mixed: 25, price: 0.86 }, // Date: 2023-01-21
  { x: 1674259200, positive: 85, negative: 10, mixed: 5, price: 0.82 }, // Date: 2023-01-22
  { x: 1674345600, positive: 55, negative: 35, mixed: 10, price: 0.87 }, // Date: 2023-01-23
  { x: 1674432000, positive: 60, negative: 30, mixed: 10, price: 0.9 }, // Date: 2023-01-24
  { x: 1674518400, positive: 75, negative: 20, mixed: 5, price: 1.2 }, // Date: 2023-01-25
  { x: 1674604800, positive: 45, negative: 50, mixed: 5, price: 0.92 }, // Date: 2023-01-26
  { x: 1674691200, positive: 60, negative: 35, mixed: 5, price: 0.89 }, // Date: 2023-01-27
  { x: 1674777600, positive: 25, negative: 55, mixed: 20, price: 0.4 }, // Date: 2023-01-28
  { x: 1674864000, positive: 80, negative: 15, mixed: 5, price: 0.5 }, // Date: 2023-01-29
  { x: 1674950400, positive: 45, negative: 50, mixed: 5, price: 0.6 }, // Date: 2023-01-30
  { x: 1675036800, positive: 60, negative: 35, mixed: 5, price: 0.65 }, // Date: 2023-01-31
  { x: 1675123200, positive: 70, negative: 30, mixed: 0, price: 0.8 }, // Date: 2023-02-01
  { x: 1675209600, positive: 60, negative: 35, mixed: 5, price: 1.02 }, // Date: 2023-02-02
  { x: 1675296000, positive: 50, negative: 40, mixed: 10, price: 1.04 }, // Date: 2023-02-03
  { x: 1675382400, positive: 35, negative: 50, mixed: 15, price: 1.07 }, // Date: 2023-02-04
  { x: 1675468800, positive: 55, negative: 35, mixed: 10, price: 1.09 }, // Date: 2023-02-05
  { x: 1675555200, positive: 70, negative: 30, mixed: 0, price: 1.11 }, // Date: 2023-02-06
  { x: 1675641600, positive: 45, negative: 40, mixed: 15, price: 1.13 }, // Date: 2023-02-07
  { x: 1675728000, positive: 60, negative: 35, mixed: 5, price: 1.16 }, // Date: 2023-02-08
  { x: 1675814400, positive: 30, negative: 55, mixed: 15, price: 1.19 }, // Date: 2023-02-09
  { x: 1675900800, positive: 70, negative: 30, mixed: 0, price: 0.8 }, // Date: 2023-02-10
  { x: 1675987200, positive: 45, negative: 40, mixed: 15, price: 1.25 }, // Date: 2023-02-11
  { x: 1676073600, positive: 60, negative: 35, mixed: 5, price: 1.28 }, // Date: 2023-02-12
  { x: 1676160000, positive: 75, negative: 20, mixed: 5, price: 1.45 }, // Date: 2023-02-13
  { x: 1676246400, positive: 40, negative: 45, mixed: 15, price: 1.75 }, // Date: 2023-02-14
  { x: 1676332800, positive: 65, negative: 25, mixed: 10, price: 1.55 }, // Date: 2023-02-15
  { x: 1676419200, positive: 30, negative: 55, mixed: 15, price: 1.85 }, // Date: 2023-02-16
  { x: 1676505600, positive: 75, negative: 20, mixed: 5, price: 2.05 }, // Date: 2023-02-17
  { x: 1676592000, positive: 40, negative: 45, mixed: 15, price: 2.35 }, // Date: 2023-02-18
  { x: 1676678400, positive: 55, negative: 35, mixed: 10, price: 2.0 }, // Date: 2023-02-19
  { x: 1676764800, positive: 70, negative: 30, mixed: 0, price: 2.45 }, // Date: 2023-02-20
  { x: 1676851200, positive: 35, negative: 50, mixed: 15, price: 1.6 }, // Date: 2023-02-21
  { x: 1676937600, positive: 60, negative: 35, mixed: 5, price: 2.55 }, // Date: 2023-02-22
  { x: 1677024000, positive: 75, negative: 20, mixed: 5, price: 2.85 }, // Date: 2023-02-23
  { x: 1677110400, positive: 40, negative: 45, mixed: 15, price: 3.15 }, // Date: 2023-02-24
  { x: 1677196800, positive: 55, negative: 35, mixed: 10, price: 2.95 }, // Date: 2023-02-25
  { x: 1677283200, positive: 70, negative: 30, mixed: 0, price: 3.25 }, // Date: 2023-02-26
  { x: 1677369600, positive: 35, negative: 50, mixed: 15, price: 3.55 }, // Date: 2023-02-27
  { x: 1677456000, positive: 60, negative: 35, mixed: 5, price: 3.35 }, // Date: 2023-02-28
  { x: 1677542400, positive: 90, negative: 10, mixed: 0, price: 2.79 }, // Date: 2023-03-01
  { x: 1677628800, positive: 20, negative: 60, mixed: 20, price: 2.82 }, // Date: 2023-03-02
  { x: 1677715200, positive: 80, negative: 10, mixed: 10, price: 2.35 }, // Date: 2023-03-03
  { x: 1677801600, positive: 30, negative: 40, mixed: 30, price: 2.88 }, // Date: 2023-03-04
  { x: 1677888000, positive: 70, negative: 10, mixed: 20, price: 2.91 }, // Date: 2023-03-05
  { x: 1677974400, positive: 40, negative: 50, mixed: 10, price: 2.94 }, // Date: 2023-03-06
  { x: 1678060800, positive: 60, negative: 30, mixed: 10, price: 2.97 }, // Date: 2023-03-07
  { x: 1678147200, positive: 50, negative: 40, mixed: 10, price: 2.0 }, // Date: 2023-03-08
];

export const mentionsCoords: IDataPoint[] = [
        { x: 1669852800, y: 0, y2: 5 }, // Date: 2022-12-01
        { x: 1669939200, y: 3, y2: 8 }, // Date: 2022-12-02
        { x: 1670025600, y: 6, y2: 11 }, // Date: 2022-12-03
        { x: 1670112000, y: 9, y2: 14 }, // Date: 2022-12-04
        { x: 1670198400, y: 12, y2: 17 }, // Date: 2022-12-05
        { x: 1670284800, y: 15, y2: 20 }, // Date: 2022-12-06
        { x: 1670371200, y: 18, y2: 23 }, // Date: 2022-12-07
        { x: 1670457600, y: 21, y2: 26 }, // Date: 2022-12-08
        { x: 1670544000, y: 24, y2: 29 }, // Date: 2022-12-09
        { x: 1670630400, y: 27, y2: 32 }, // Date: 2022-12-10
        { x: 1670716800, y: 30, y2: 35 }, // Date: 2022-12-11
        { x: 1670803200, y: 33, y2: 38 }, // Date: 2022-12-12
        { x: 1670889600, y: 36, y2: 41 }, // Date: 2022-12-13
        { x: 1670976000, y: 39, y2: 44 }, // Date: 2022-12-14
        { x: 1671062400, y: 42, y2: 47 }, // Date: 2022-12-15
        { x: 1671148800, y: 45, y2: 50 }, // Date: 2022-12-16
        { x: 1671235200, y: 48, y2: 53 }, // Date: 2022-12-17
        { x: 1671321600, y: 51, y2: 56 }, // Date: 2022-12-18
        { x: 1671408000, y: 54, y2: 59 }, // Date: 2022-12-19
        { x: 1671494400, y: 57, y2: 62 }, // Date: 2022-12-20
        { x: 1671580800, y: 60, y2: 65 }, // Date: 2022-12-21
        { x: 1671667200, y: 63, y2: 68 }, // Date: 2022-12-22
        { x: 1671753600, y: 66, y2: 71 }, // Date: 2022-12-23
        { x: 1671840000, y: 69, y2: 74 }, // Date: 2022-12-24
        { x: 1671926400, y: 72, y2: 77 }, // Date: 2022-12-25
        { x: 1672012800, y: 75, y2: 80 }, // Date: 2022-12-26
        { x: 1672099200, y: 78, y2: 83 }, // Date: 2022-12-27
        { x: 1672185600, y: 81, y2: 86 }, // Date: 2022-12-28
        { x: 1672272000, y: 84, y2: 89 }, // Date: 2022-12-29
        { x: 1672358400, y: 87, y2: 92 }, // Date: 2022-12-30
        { x: 1672444800, y: 90, y2: 95 }, // Date: 2022-12-31
        { x: 1672444800, y: 0, y2: 5 * Math.random() * 3 }, // Date: 2023-01-01
        { x: 1672531200, y: 7, y2: 10 * Math.random() * 3 }, // Date: 2023-01-02
        { x: 1672617600, y: 12, y2: 15 * Math.random() * 3 }, // Date: 2023-01-03
        { x: 1672704000, y: 24, y2: 30 * Math.random() * 3 }, // Date: 2023-01-04
        { x: 1672790400, y: 6, y2: 12 * Math.random() * 3 }, // Date: 2023-01-05
        { x: 1672876800, y: 13, y2: 20 * Math.random() * 3 }, // Date: 2023-01-06
        { x: 1672963200, y: 8, y2: 16 * Math.random() * 3 }, // Date: 2023-01-07
        { x: 1673049600, y: 20, y2: 25 * Math.random() * 3 }, // Date: 2023-01-08
        { x: 1673136000, y: 12, y2: 24 * Math.random() * 3 }, // Date: 2023-01-09
        { x: 1673222400, y: 16, y2: 32 * Math.random() * 3 }, // Date: 2023-01-10
        { x: 1673308800, y: 20, y2: 40 * Math.random() * 3 }, // Date: 2023-01-11
        { x: 1673395200, y: 10, y2: 20 * Math.random() * 3 }, // Date: 2023-01-12
        { x: 1673481600, y: 8, y2: 16 * Math.random() * 3 }, // Date: 2023-01-13
        { x: 1673568000, y: 10, y2: 20 * Math.random() * 3 }, // Date: 2023-01-14
        { x: 1673654400, y: 12, y2: 24 * Math.random() * 3 }, // Date: 2023-01-15
        { x: 1673740800, y: 27, y2: 54 * Math.random() * 3 }, // Date: 2023-01-16
        { x: 1673827200, y: 20, y2: 40 * Math.random() * 3 }, // Date: 2023-01-17
        { x: 1673913600, y: 20, y2: 40 * Math.random() * 3 }, // Date: 2023-01-18
        { x: 1674000000, y: 33, y2: 66 * Math.random() * 3 }, // Date: 2023-01-19
        { x: 1674086400, y: 45, y2: 90 * Math.random() * 3 }, // Date: 2023-01-20
        { x: 1674172800, y: 70, y2: 140 * Math.random() * 3 }, // Date: 2023-01-21
        { x: 1674259200, y: 50, y2: 100 * Math.random() * 3 }, // Date: 2023-01-22
        { x: 1674345600, y: 42, y2: 110 * Math.random() * 3 }, // Date: 2023-01-23
        { x: 1674432000, y: 40, y2: 120 * Math.random() * 3 }, // Date: 2023-01-24
        { x: 1674518400, y: 35, y2: 130 * Math.random() * 3 }, // Date: 2023-01-25
        { x: 1674604800, y: 70, y2: 140 * Math.random() * 3 }, // Date: 2023-01-26
        { x: 1674691200, y: 75, y2: 150 * Math.random() * 3 }, // Date: 2023-01-27
        { x: 1674777600, y: 70, y2: 160 * Math.random() * 3 }, // Date: 2023-01-28
        { x: 1674864000, y: 63, y2: 170 * Math.random() * 3 }, // Date: 2023-01-29
        { x: 1674950400, y: 90, y2: 180 * Math.random() * 3 }, // Date: 2023-01-30
        { x: 1675036800, y: 70, y2: 190 * Math.random() * 3 }, // Date: 2023-01-31
        { x: 1675123200, y: 100, y2: 200 * Math.random() * 3 }, // Date: 2023-02-01
];

const coinsArrayCoords = [
  { x: 1640995200, y: 0 },
  { x: 1641782400, y: 3 },
  { x: 1642569600, y: 3 },
  { x: 1643356800, y: 3 },
  { x: 1644144000, y: 6 },
  { x: 1644931200, y: 3 },
  { x: 1645718400, y: 8 },
  { x: 1646505600, y: 3 },
  { x: 1647292800, y: 3 },
  { x: 1648080000, y: 3 },
  { x: 1648867200, y: 6 },
  { x: 1649654400, y: 10 },
  { x: 1650441600, y: 8 },
  { x: 1651228800, y: 10 },
  { x: 1652016000, y: 6 },
  { x: 1652803200, y: 3 },
  { x: 1653590400, y: 6 },
  { x: 1654377600, y: 3 },
  { x: 1655164800, y: 3 },
  { x: 1655952000, y: 21 },
  { x: 1656739200, y: 3 },
]

export const coinsArray = [
  {
    label: 'Coin',
    coin: 'Monai',
    symbol: 'MONAI',
    categories: ['Artificial Intelligence'],
    price: 0.1179,
    activityChange: 45,
    chain: 'Ethereum',
    logo: 'https://img.cryptorank.io/coins/150x150.monai1709042976087.png',
    stats: [
      { label: 'mentions', value: 82 },
      { label: 'views', value: 10278 },
      { label: 'channels', value: 25 },
    ],
    spark: [6, 12, 73, 8, 12, 33],
    coords:  coinsArrayCoords,
  },
  {
    label: 'Coin',
    coin: 'WAGMI Games',
    symbol: 'WAGMIGAMES',
    categories: ['Gaming'],
    price: 0.00001132,
    activityChange: -2,
    chain: 'Base',
    logo: 'https://assets.coingecko.com/coins/images/25906/standard/WAGMI_DEFENSE_LOGO24_coingecko.png?1708591845',
    stats: [
      { label: 'mentions', value: 930 },
      { label: 'views', value: 3092 },
      { label: 'channels', value: 35 },
    ],
    spark: [33, 23, 56, 78, 90],
    coords:  multiplyByRandomNumber(coinsArrayCoords),
  },
];

export const newCoinsArray = [
  {
    label: 'Coin',
    coin: 'Neural AI',
    symbol: 'NEURAL',
    categories: ['Artificial Intelligence'],
    price: 2.53,
    activityChange: 13,
    chain: 'Ethereum',
    logo: 'https://assets.coingecko.com/coins/images/36341/standard/NeuralAI_Token_Logo.png?1712340484',
    stats: [
      { label: 'mentions', value: 1930 },
      { label: 'views', value: 2090 },
      { label: 'channels', value: 310 },
    ],
    spark: [33, 23, 56, 78, 90],
    coords:  multiplyByRandomNumber(coinsArrayCoords),
  },
  {
    label: 'Coin',
    coin: 'Brett',
    symbol: 'BRETT',
    categories: ['Meme'],
    price: 0.1591,
    activityChange: 45,
    chain: 'Base',
    logo: 'https://assets.coingecko.com/coins/images/35529/standard/1000050750.png?1709031995',
    stats: [
      { label: 'mentions', value: 82 },
      { label: 'views', value: 10278 },
      { label: 'channels', value: 25 },
    ],
    spark: [6, 12, 73, 8, 12, 33],
    coords:  coinsArrayCoords,
  },
  {
    label: 'Coin',
    coin: 'Resistance Dog',
    symbol: 'REDO',
    categories: ['Meme'],
    price: 0.675,
    activityChange: -2,
    chain: 'Ton',
    logo: 'https://assets.coingecko.com/coins/images/34602/standard/hoodie000-removebg-preview.png?1705492066',
    stats: [
      { label: 'mentions', value: 930 },
      { label: 'views', value: 3092 },
      { label: 'channels', value: 35 },
    ],
    spark: [33, 23, 56, 78, 90],
    coords:  multiplyByRandomNumber(coinsArrayCoords),
  },
  {
    label: 'Coin',
    coin: 'OpenFabric AI',
    symbol: 'OFN',
    categories: ['Artificial Intelligence'],
    price: 2.53,
    activityChange: 13,
    chain: 'Binance',
    logo: 'https://assets.coingecko.com/coins/images/32171/standard/IMG_20231005_191903_018.jpg?1696741847',
    stats: [
      { label: 'mentions', value: 1930 },
      { label: 'views', value: 2090 },
      { label: 'channels', value: 310 },
    ],
    spark: [33, 23, 56, 78, 90],
    coords:  multiplyByRandomNumber(coinsArrayCoords),
  },
  {
    label: 'Coin',
    coin: 'SingularityNET',
    symbol: 'AGIX',
    categories: ['Artificial Intelligence'],
    price: 3.45,
    activityChange: 20,
    chain: 'Ethereum',
    logo: 'https://assets.coingecko.com/coins/images/2138/standard/singularitynet.png?1696503103',
    stats: [
      { label: 'mentions', value: 2500 },
      { label: 'views', value: 3000 },
      { label: 'channels', value: 400 },
    ],
    spark: [40, 30, 60, 80, 100],
    coords: multiplyByRandomNumber(coinsArrayCoords),
  },
  {
    label: 'Coin',
    coin: 'Arbitrum',
    symbol: 'ARB',
    categories: ['DePin'],
    price: 5.67,
    activityChange: 15,
    chain: 'Ethereum',
    logo: 'https://assets.coingecko.com/coins/images/16547/standard/photo_2023-03-29_21.47.00.jpeg?1696516109',
    stats: [
      { label: 'mentions', value: 3500 },
      { label: 'views', value: 4500 },
      { label: 'channels', value: 500 },
    ],
    spark: [50, 40, 70, 90, 110],
    coords: multiplyByRandomNumber(coinsArrayCoords),
  },
  {
    label: 'Coin',
    coin: 'Pocket Network',
    symbol: 'POKT',
    categories: ['RWA'],
    price: 7.89,
    activityChange: 18,
    chain: 'Ethereum',
    logo: 'https://assets.coingecko.com/coins/images/22506/standard/POKT.jpg?1703257310',
    stats: [
      { label: 'mentions', value: 4500 },
      { label: 'views', value: 5500 },
      { label: 'channels', value: 600 },
    ],
    spark: [60, 50, 80, 100, 120],
    coords: multiplyByRandomNumber(coinsArrayCoords),
  },
  {
    label: 'Coin',
    coin: 'Playa3ull Games',
    symbol: '3ULL',
    categories: ['GameFi'],
    price: 9.12,
    activityChange: 22,
    chain: 'Ethereum',
    logo: 'https://assets.coingecko.com/coins/images/30695/standard/App_Icon_Round.png?1696529564',
    stats: [
      { label: 'mentions', value: 5500 },
      { label: 'views', value: 6500 },
      { label: 'channels', value: 700 },
    ],
    spark: [70, 60, 90, 110, 130],
    coords: multiplyByRandomNumber(coinsArrayCoords),
  }
];

const chainCoords = [
  { x: 1640995200, y: 0 },
  { x: 1641782400, y: 3 },
  { x: 1642569600, y: 3 },
  { x: 1643356800, y: 3 },
  { x: 1644144000, y: 6 },
  { x: 1644931200, y: 3 },
  { x: 1645718400, y: 8 },
  { x: 1646505600, y: 3 },
  { x: 1647292800, y: 3 },
  { x: 1648080000, y: 3 },
  { x: 1648867200, y: 6 },
  { x: 1649654400, y: 10 },
  { x: 1650441600, y: 8 },
  { x: 1651228800, y: 10 },
  { x: 1652016000, y: 6 },
  { x: 1652803200, y: 3 },
  { x: 1653590400, y: 6 },
  { x: 1654377600, y: 3 },
  { x: 1655164800, y: 3 },
  { x: 1655952000, y: 21 },
  { x: 1656739200, y: 3 },
];

let chainsArray = [
  {
    label: 'Blockchain',
    title: 'Ethereum',
    thirdTitle: 'ETH',
    logo: 'https://img.cryptorank.io/coins/ethereum1524754015525.png',
    percentChange: 45,
    stats: [
      { label: 'views', value: 250000 },
      { label: 'total', value: 1293 },
      { label: 'channels', value: 25 },
    ],
    coords: [],
    spark: [],
  },
  {
    label: 'Blockchain',
    title: 'Binance Smart',
    thirdTitle: 'BSC',
    logo: 'https://img.cryptorank.io/coins/bnb1702897354788.png',
    percentChange: 20, 
    stats: [
      { label: 'views', value: 930 },
      { label: 'total', value: 1293 },
      { label: 'channels', value: 60 }, 
    ],
    coords: [],
    spark: [],
  },
  {
    label: 'Blockchain',
    title: 'Solana',
    thirdTitle: 'SOL',
    logo: 'https://img.cryptorank.io/coins/solana1606979093056.png',
    percentChange: 15, 
    stats: [
      { label: 'views', value: 1293 },
      { label: 'total', value: 2500 },
      { label: 'channels', value: 60 }, 
    ],
    coords: [],
    spark: [],
  },
  {
    label: 'Blockchain',
    title: 'Arbitrum One',
    thirdTitle: 'ARB',
    logo: 'https://assets.coingecko.com/asset_platforms/images/33/standard/AO_logomark.png?1706606717',
    percentChange: 10, 
    stats: [
      { label: 'views', value: 730 },
      { label: 'total', value: 5670 },
      { label: 'channels', value: 70 }, 
    ],
    coords: [],
    spark: [],
  },
  {
    label: 'Blockchain',
    title: 'Polkadot',
    thirdTitle: 'DOT',
    logo: 'https://assets.coingecko.com/asset_platforms/images/12171/standard/polkadot.jpeg?1706606400',
    percentChange: 7, 
    stats: [
      { label: 'views', value: 1293 },
      { label: 'total', value: 4000 },
      { label: 'channels', value: 60 }, 
    ],
    coords: [],
    spark: [],
  },
  {
    label: 'Blockchain',
    title: 'Avalanche',
    thirdTitle: 'AVAX',
    logo: 'https://assets.coingecko.com/asset_platforms/images/12/standard/avalanche.png?1706606775',
    percentChange: 3, 
    stats: [
      { label: 'views', value: 1293 },
      { label: 'total', value: 3500 },
      { label: 'channels', value: 60 }, 
    ],
    coords: [],
    spark: [],
  },
  {
    label: 'Blockchain',
    title: 'Polygon',
    thirdTitle: 'MATIC',
    logo: 'https://assets.coingecko.com/asset_platforms/images/15/standard/polygon_pos.png?1706606645',
    percentChange: 30, 
    stats: [
      { label: 'views', value: 1293 },
      { label: 'total', value: 2000 },
      { label: 'channels', value: 60 }, 
    ],
    coords: [],
    spark: [],
  },
  {
    label: 'Blockchain',
    title: 'Blast',
    thirdTitle: 'BLAST',
    logo: 'https://assets.coingecko.com/asset_platforms/images/192/standard/blast.jpeg?1709085131',
    percentChange: -5, 
    stats: [
      { label: 'views', value: 1293 },
      { label: 'total', value: 4500 },
      { label: 'channels', value: 60 }, 
    ],
    coords: [],
    spark: [],
  },
  {
    label: 'Blockchain',
    title: 'Tron',
    thirdTitle: 'TRX',
    logo: 'https://assets.coingecko.com/asset_platforms/images/1094/standard/TRON_LOGO.png?1706606652',
    percentChange: -10, 
    stats: [
      { label: 'views', value: 1293 },
      { label: 'total', value: 1000 },
      { label: 'channels', value: 60 }, 
    ],
    coords: [],
    spark: [],
  }
].map(chain => {
  const chainCoordsNew = multiplyByRandomNumber(chainCoords);

  return {
    ...chain,
    coords: chainCoordsNew,
    spark: chainCoordsNew.filter((_, i) => i % 2 === 1).map((coord) => coord.y)
  };
});

export { chainsArray };

const channelCoords = [
  { x: 1640995200, y: 0 },
  { x: 1641782400, y: 2 },
  { x: 1642569600, y: 3 },
  { x: 1643356800, y: 23 },
  { x: 1644144000, y: 6 },
  { x: 1644931200, y: 7 },
  { x: 1645718400, y: 8 },
  { x: 1646505600, y: 20 },
  { x: 1647292800, y: 12 },
  { x: 1648080000, y: 16 },
  { x: 1648867200, y: 23 },
  { x: 1649654400, y: 10 },
  { x: 1650441600, y: 8 },
  { x: 1651228800, y: 19 },
  { x: 1652016000, y: 12 },
  { x: 1652803200, y: 27 },
  { x: 1653590400, y: 45 },
  { x: 1654377600, y: 56 },
  { x: 1655164800, y: 82 },
  { x: 1655952000, y: 110 },
  { x: 1656739200, y: 90 },
];

export const channelArray = [
  {
    label: 'Channel',
    title: 'Crypto Gains',
    secondTitle: 'cryptogainschannel',
    logo: 'https://yt3.googleusercontent.com/-gWb8BOCaMBnxUR_KoECDwKQK0-JNKLBAlm3Wm4wWV3Ku8ozq8j0_Nq2AL2WQtSEo7TCiersMQ=s176-c-k-c0x00ffffff-no-rj',
    activityChange: 45,
    stats: [
      { label: 'total views', value: 255000, change: 12 },
      { label: 'average views', value: 592, change: 34 },
      { label: 'channels', value: 25, change: 3 },
    ],
    coords: multiplyByRandomNumber(channelCoords),
    spark: [6, 12, 73, 8, 12, 33],
  },
  {
    label: 'Channel',
    title: 'Altcoin Buzz',
    secondTitle: 'AltcoinBuzz',
    logo: 'https://yt3.googleusercontent.com/ytc/AIdro_mBGjx6UsQut7BtCK_-CrohAILGEqThaKqPp4hfJ9nRNA=s176-c-k-c0x00ffffff-no-rj',
    activityChange: 40,
    stats: [
      { label: 'total views', value: 930, change: -45 },
      { label: 'average views', value: 1293, change: 50 },
      { label: 'channels', value: 60, change: 5 }, 
    ],
    coords: multiplyByRandomNumber(channelCoords),
    spark: [33, 23, 56, 78, 90],
  },
  {
    label: 'Channel',
    title: 'Chico Crypto',
    secondTitle: 'ChicoCrypto',
    logo: 'https://yt3.googleusercontent.com/ytc/AIdro_nnLmNyplGXk6rZsfG7QUSnJFzjjBuHhPEtCxQzaamAIek=s176-c-k-c0x00ffffff-no-rj',
    activityChange: 38,
    stats: [
      { label: 'total views', value: 1293, change: 4 },
      { label: 'average views', value: 2500, change: 17 },
      { label: 'channels', value: 33, change: 50 }, 
    ],
    coords: multiplyByRandomNumber(channelCoords),
    spark: [56, 90, 33, 78, 23],
  },
  {
    label: 'Channel',
    title: 'Crypto Zombie',
    secondTitle: 'CryptoZombie',
    logo: 'https://yt3.googleusercontent.com/ytc/AIdro_nHM1QeqEY_4kOAMlYcs5BPplZPOLWFv04DVqweY2bkYhc=s176-c-k-c0x00ffffff-no-rj',
    activityChange: 13,
    stats: [
      { label: 'total views', value: 5453, change: -3 },
      { label: 'average views', value: 4000, change: 22 },
      { label: 'channels', value: 70, change: 6 }, 
    ],
    coords: multiplyByRandomNumber(channelCoords),
    spark: [23, 90, 78, 56, 33],
  },
  {
    label: 'Channel',
    title: 'That Martini Guy',
    secondTitle: 'ThatMartiniGuy',
    logo: 'https://yt3.googleusercontent.com/ytc/AIdro_mFmm2zCFy0m13E1tP6eaRRmT-R7DDTIfSA8vKV8JJ7wDE=s176-c-k-c0x00ffffff-no-rj',
    activityChange: -4,
    stats: [
      { label: 'total views', value: 7974, change: -49 },
      { label: 'average views', value: 1500, change: 36 },
      { label: 'channels', value: 60, change: 15 }, 
    ],
    coords: multiplyByRandomNumber(channelCoords),
    spark: [33, 78, 56, 90, 23],
  },
  {
    label: 'Channel',
    title: "Alex Becker's Channel",
    secondTitle: 'AlexBeckersChannel',
    logo: 'https://yt3.googleusercontent.com/K9P2-edlG8yM4vA2D0simxZNR4Rw6LsM4PIsrwx45GOJrWQVzw7Kr1-SUVT6cz4tPwG52PRI=s176-c-k-c0x00ffffff-no-rj',
    activityChange: -12,
    stats: [
      { label: 'total views', value: 286, change: 45 },
      { label: 'average views', value: 3500, change: -15 },
      { label: 'channels', value: 60, change: 32 }, 
    ],
    coords: multiplyByRandomNumber(channelCoords),
    spark: [78, 56, 33, 90, 23],
  },
  {
    label: 'Channel',
    title: 'Crypto Banter',
    secondTitle: 'CryptoBanterGroup',
    logo: 'https://yt3.googleusercontent.com/X8OQhsp-TIY7AI1f4HDpYDJhk4WIBvHu266ZxEIrY2pbRLzxmrfhSwLqoFjHHVrqhGGmpik3Fg=s176-c-k-c0x00ffffff-no-rj',
    activityChange: -17,
    stats: [
      { label: 'total views', value: 907654, change: 44 },
      { label: 'average views', value: 2000, change: -7 },
      { label: 'channels', value: 60, change: 12 }, 
    ],
    coords: multiplyByRandomNumber(channelCoords),
    spark: [33, 78, 56, 23, 90],
  },
  {
    label: 'Channel',
    title: 'The Cryptoviser',
    secondTitle: 'TheCryptoviser',
    logo: 'https://yt3.googleusercontent.com/ytc/AIdro_ns0uV4sLl6qo7ZUZwrtSjvQ8OA9LR5TdzYoP_tch4h0uI=s176-c-k-c0x00ffffff-no-rj',
    activityChange: 8,
    stats: [
      { label: 'total views', value: 22341, change: 26 },
      { label: 'average views', value: 3000, change: -3 },
      { label: 'channels', value: 60, change: 12 }, 
    ],
    coords: multiplyByRandomNumber(channelCoords),
    spark: [23, 90, 78, 56, 33],
  },
  {
    label: 'Channel',
    title: 'VirtualBacon',
    secondTitle: 'VirtualBacon',
    logo: 'https://yt3.googleusercontent.com/atj6qleGGxNX39X3zBIJ17oG3MdEfd90U8lL5MpqKhxboO20_FsOJIrnEKuEZvdiKjcTx6ajGg=s176-c-k-c0x00ffffff-no-rj',
    activityChange: -3,
    stats: [
      { label: 'total views', value: 293, change: 39 },
      { label: 'average views', value: 4500, change: -20 },
      { label: 'channels', value: 60, change: 23 }, 
    ],
    coords: multiplyByRandomNumber(channelCoords),
    spark: [56, 33, 23, 90, 78],
  },
  {
    label: 'Channel',
    title: 'CryptosRUs',
    secondTitle: 'CryptosRUs',
    logo: 'https://yt3.googleusercontent.com/ytc/AIdro_mpKY9I7FUShJOGvuOsCaspa8lvJaRlDrkgA9oyTqx14qo=s176-c-k-c0x00ffffff-no-rj',
    activityChange: 9,
    stats: [
      { label: 'total views', value: 1293, change: 11 },
      { label: 'average views', value: 1000, change: 45 },
      { label: 'channels', value: 60, change: 23 }, 
    ],
    coords: multiplyByRandomNumber(channelCoords),
    spark: [90, 56, 33, 78, 23],
  },
];

const categoryCoords = [
  { x: 1640995200, y: 0 },
  { x: 1641782400, y: 2 },
  { x: 1642569600, y: 3 },
  { x: 1643356800, y: 23 },
  { x: 1644144000, y: 6 },
  { x: 1644931200, y: 7 },
  { x: 1645718400, y: 8 },
  { x: 1646505600, y: 20 },
  { x: 1647292800, y: 12 },
  { x: 1648080000, y: 16 },
  { x: 1648867200, y: 23 },
  { x: 1649654400, y: 10 },
  { x: 1650441600, y: 8 },
  { x: 1651228800, y: 19 },
  { x: 1652016000, y: 12 },
  { x: 1652803200, y: 27 },
  { x: 1653590400, y: 45 },
  { x: 1654377600, y: 56 },
  { x: 1655164800, y: 82 },
  { x: 1655952000, y: 110 },
  { x: 1656739200, y: 90 },
];

export const categoriesArray =  [
  {
    label: 'Category',
    title: 'Liquid Restaking',
    thirdTitle: 'Liquid Restaking',
    logo: '',
    logoClass: 'fa-hand-holding-droplet',
    stats: [
      { label: 'average views', value: 250000, change: 25 },
      { label: 'total views', value: 1293, change: 12 },
      { label: 'channels', value: 25, change: 3 },
    ],
    coords: [],
    spark: [],
  },
  {
    label: 'Category',
    title: 'Meme Coins',
    thirdTitle: 'Meme',
    logo: '',
    logoClass: 'fa-dog',
    stats: [
      { label: 'average views', value: 1293, change:  26 },
      { label: 'total views', value: 1000, change: -3 },
      { label: 'channels', value: 60, change: 23 }, 
    ],
    coords: [],
    spark: [],
  },
  {
    label: 'Category',
    title: 'Decentralized Physical Infrastructure Networks',
    thirdTitle: 'Depin',
    logo: '',
    logoClass: 'fa-server',
    stats: [
      { label: 'average views', value: 1293, change: -45 },
      { label: 'total views', value: 2500, change:  50 },
      { label: 'channels', value: 60, change: 50 }, 
    ],
    coords: [],
    spark: [],
  },
  {
    label: 'Category',
    title: 'Decentralized Storage',
    thirdTitle: 'Storage',
    logo: '',
    logoClass: 'fa-database',
    stats: [
      { label: 'average views', value: 730, change:  4 },
      { label: 'total views', value: 5670, change:  17 },
      { label: 'channels', value: 70, change: 6 }, 
    ],
    coords: [],
    spark: [],
  },
  {
    label: 'Category',
    title: 'Decentralized Finance',
    thirdTitle: 'DeFi',
    logo: '',
    logoClass: 'fa-piggy-bank',
    stats: [
      { label: 'average views', value: 1293, change: -3 },
      { label: 'total views', value: 4000, change:  22 },
      { label: 'channels', value: 60, change: 15 }, 
    ],
    coords: [],
    spark: [],
  },
  {
    label: 'Category',
    title: 'Artificial Intelligence',
    thirdTitle: 'AI',
    logo: '',
    logoClass: 'fa-user-robot',
    stats: [
      { label: 'average views', value: 1293, change: -49 },
      { label: 'total views', value: 3500, change:  36 },
      { label: 'channels', value: 60, change: 32 }, 
    ],
    coords: [],
    spark: [],
  },
  {
    label: 'Category',
    title: 'Virtual Reality',
    thirdTitle: 'VR',
    logo: '',
    logoClass: 'fa-vr-cardboard',
    stats: [
      { label: 'average views', value: 1293, change:  45 },
      { label: 'total views', value: 2000, change: -15 },
      { label: 'channels', value: 60, change: 12 }, 
    ],
    coords: [],
    spark: [],
  },
  {
    label: 'Category',
    title: 'Privacy',
    thirdTitle: 'Privacy',
    logo: '',
    logoClass: 'fa-lock',
    stats: [
      { label: 'average views', value: 1293, change:  44 },
      { label: 'total views', value: 4500, change: -7 },
      { label: 'channels', value: 60, change: 12 }, 
    ],
    coords: [],
    spark: [],
  },
  {
    label: 'Category',
    title: 'Real World Assets',
    thirdTitle: 'RWA',
    logo: '',
    logoClass: 'fa-house',
    stats: [
      { label: 'average views', value: 930, change:  12 },
      { label: 'total views', value: 1293, change:  34 },
      { label: 'channels', value: 60, change: 5 }, 
    ],
    coords: [],
    spark: [],
  },
].map(chain => {
  const categoryCoordsNew = multiplyByRandomNumber(categoryCoords);

  return {
    ...chain,
    coords: categoryCoordsNew,
    spark: categoryCoordsNew.filter((_, i) => i % 2 === 1).map((coord) => coord.y)
  };
});

const buzzwordsCoords = [
  { x: 1640995200, y: 0 },
  { x: 1641782400, y: 2 },
  { x: 1642569600, y: 3 },
  { x: 1643356800, y: 23 },
  { x: 1644144000, y: 6 },
  { x: 1644931200, y: 7 },
  { x: 1645718400, y: 8 },
  { x: 1646505600, y: 20 },
  { x: 1647292800, y: 12 },
  { x: 1648080000, y: 16 },
  { x: 1648867200, y: 23 },
  { x: 1649654400, y: 10 },
  { x: 1650441600, y: 8 },
  { x: 1651228800, y: 19 },
  { x: 1652016000, y: 12 },
  { x: 1652803200, y: 27 },
  { x: 1653590400, y: 45 },
  { x: 1654377600, y: 56 },
  { x: 1655164800, y: 82 },
  { x: 1655952000, y: 110 },
  { x: 1656739200, y: 90 },
];

export const buzzwordsArray =  [
  {
    label: 'Buzzwords',
    title: 'Halving',
    stats: [
      { label: 'average views', value: 250000, change: 25 },
      { label: 'total views', value: 1293, change: 12 },
      { label: 'channels', value: 25, change: 3 },
    ],
    coords: [],
    spark: [],
  },
  {
    label: 'Buzzwords',
    title: 'Fork',
    stats: [
      { label: 'average views', value: 10456, change:  26 },
      { label: 'total views', value: 1000, change: -3 },
      { label: 'channels', value: 60, change: 23 }, 
    ],
    coords: [],
    spark: [],
  },
  {
    label: 'Buzzwords',
    title: 'IDO',
    stats: [
      { label: 'average views', value: 1293, change: -45 },
      { label: 'total views', value: 2500, change:  50 },
      { label: 'channels', value: 60, change: 50 }, 
    ],
    coords: [],
    spark: [],
  },
  {
    label: 'Buzzwords',
    title: 'Total3',
    stats: [
      { label: 'average views', value: 930, change:  12 },
      { label: 'total views', value: 1293, change:  34 },
      { label: 'channels', value: 60, change: 5 }, 
    ],
    coords: [],
    spark: [],
  },
  {
    label: 'Buzzwords',
    title: 'Meme Coins',
    stats: [
      { label: 'average views', value: 730, change:  4 },
      { label: 'total views', value: 5670, change:  17 },
      { label: 'channels', value: 70, change: 6 }, 
    ],
    coords: [],
    spark: [],
  },
  {
    label: 'Buzzwords',
    title: 'Dapp',
    stats: [
      { label: 'average views', value: 1293, change: -3 },
      { label: 'total views', value: 4000, change:  22 },
      { label: 'channels', value: 60, change: 15 }, 
    ],
    coords: [],
    spark: [],
  },
  {
    label: 'Buzzwords',
    title: 'Proof of Stake',
    stats: [
      { label: 'average views', value: 1293, change: -49 },
      { label: 'total views', value: 3500, change:  36 },
      { label: 'channels', value: 60, change: 32 }, 
    ],
    coords: [],
    spark: [],
  },
  {
    label: 'Buzzwords',
    title: 'Tokenomics',
    stats: [
      { label: 'average views', value: 1293, change:  45 },
      { label: 'total views', value: 2000, change: -15 },
      { label: 'channels', value: 60, change: 12 }, 
    ],
    coords: [],
    spark: [],
  },
  {
    label: 'Buzzwords',
    title: 'Miner Capitulation',
    stats: [
      { label: 'average views', value: 1293, change:  44 },
      { label: 'total views', value: 4500, change: -7 },
      { label: 'channels', value: 60, change: 12 }, 
    ],
    coords: [],
    spark: [],
  },
].map(chain => {
  const buzzwordCoordsNew = multiplyByRandomNumber(buzzwordsCoords);

  return {
    ...chain,
    coords: buzzwordCoordsNew,
    spark: buzzwordCoordsNew.filter((_, i) => i % 2 === 1).map((coord) => coord.y)
  };
});