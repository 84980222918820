import React from 'react';
import Title from '../Title';
import FeatureStat from '../FeatureStat';
import ChartArea from '../ChartArea';
import { ListProps } from '../../types/interfaces';

interface ChartDetailedInBannerProps {
  listItem: ListProps | null;
  featuredStatName: string;
  y1Measuring?: string;
}

const ChartDetailedInBanner: React.FC<ChartDetailedInBannerProps> = ({ listItem, featuredStatName, y1Measuring }) => (
  <div className="featured-chart">
    <div className="flex-container">
      <Title
        label={listItem ? listItem.label : ''}
        effect={true}
        title={listItem ? listItem.title : ''}
        subtitle={listItem ? `${listItem.title} Chain` : ''}
        logo={listItem ? listItem.logo : ''}
        titleTruncate={18}
      />
      <FeatureStat
        value={listItem?.stats[0]?.value || 0}
        valueChange={listItem?.percentChange}
        subtitle={featuredStatName}
        top={'3px'}
        align={'right'}
        disableMoreSuffix={true}
      />
    </div>

    <ChartArea
      y1Measuring={y1Measuring || 'views'}
      height={60}
      data={listItem?.coords || []}
      y1Colour={'#50B8E7'}
      marginTop={20}
      dots={false}
    />
  </div>
);

export default ChartDetailedInBanner;
