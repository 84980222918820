import React, { ReactNode } from 'react';
import { AreaChart, Tooltip, Area, ResponsiveContainer, TooltipProps, XAxis, YAxis, CartesianGrid } from 'recharts';
import Currency from '../../components/Currency';
import { displayTimestamp } from '../../utilities/statistical-utils';

const priceLineColour = '#8884d8';

interface ChartAreaFullProps {
  height: number;
  marginTop?: number;
  y1Measuring: string;
  y1Colour?: string; // hex
  y2Colour?: string; // hex
  dots?: boolean;
  data: {
    x: number; // timestamp
    y: number;
    y2?: number;
  }[];
  headerContent?: ReactNode;
  isMicroTooltip?: boolean;
  diagonalX?: boolean;
}

interface Payload {
  y: number;
  y2: number;
}

interface CustomTooltipProps extends TooltipProps<number, string> {
  isMicroTooltip?: boolean;
  y1Measuring: string;
  y1Colour?: string;
  y2Colour?: string;
}

const CustomTooltip: React.FC<CustomTooltipProps> = ({
  active,
  payload,
  label,
  isMicroTooltip,
  y1Measuring,
  y1Colour,
  y2Colour = priceLineColour,
}) => {
  if (active && payload && payload.length) {
    const formattedDate = displayTimestamp(label);

    return (
      <div
        className={`custom-tooltip ${isMicroTooltip ? 'micro-tooltip' : 'normal-tooltip'}`}
        style={{ transform: 'translate(80%, 580%)' }}
      >
        <div className="intro">
          <span className="color-box" style={{ backgroundColor: y1Colour }}></span>
          {`${(payload[0].payload as Payload).y} ${y1Measuring} on ${formattedDate}`}
        </div>
        {payload[0].payload.y2 !== undefined && (
          <div className="y2">
            <span className="color-box" style={{ backgroundColor: y2Colour }}></span>
            <span>{`when price was `}</span>
            <Currency value={(payload[0].payload as Payload).y2} />
          </div>
        )}
      </div>
    );
  }

  return null;
};

interface CustomTickProps {
  x: number;
  y: number;
  payload: {
    value: string;
  };
}

function formatTime(date: Date): string {
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const formattedTime = `${date.getDate()}/${date.getMonth() + 1} ${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}`;
  return formattedTime;
}

const CustomTick: React.FC<CustomTickProps> = ({ x, y, payload }) => {
  const date = new Date(+payload.value);
  const formattedTime = formatTime(date);

  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={16} textAnchor="end" fill="#ffffff" stroke="#ffffff" fontWeight="300" transform="rotate(-45)">
        {formattedTime}
      </text>
    </g>
  );
};

const ChartAreaFull: React.FC<ChartAreaFullProps> = ({
  data,
  height,
  y1Colour,
  marginTop,
  headerContent,
  isMicroTooltip,
  y1Measuring,
  dots,
  y2Colour = priceLineColour,
  diagonalX = false,
}) => {
  const hasY2 = data.some((item) => 'y2' in item);
  const minY = Math.min(...data.map((item) => item.y));
  const maxY = Math.max(...data.map((item) => item.y));
  const range = maxY - minY;
  const interval = Math.round(range / 10 / 10) * 10;
  const ticks = Array.from({ length: 11 }, (_, i) => minY + interval * i);

  return (
    <div className="chart-area" style={{ marginTop: marginTop ? marginTop : 0 }}>
      <div style={{ zIndex: 2 }}>{headerContent}</div>
      <ResponsiveContainer width="100%" height={height}>
        <AreaChart data={data} margin={{ top: 30, right: 30, left: 5, bottom: 60 }}>
          <CartesianGrid stroke="rgba(200, 200, 200, 0.2)" strokeDasharray="3 3" />
          <defs>
            <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor={y1Colour} stopOpacity={0.8} />
              <stop offset="95%" stopColor={y1Colour} stopOpacity={0} />
            </linearGradient>
          </defs>
          <XAxis
            dataKey="x"
            hide={false}
            domain={['dataMin', 'dataMax']}
            label={{ value: 'time', position: 'insideBottom', dy: 60 }}
            tick={diagonalX ? (props) => <CustomTick {...props} /> : undefined}
            tickFormatter={(unixTime) => {
              const date = new Date(unixTime);
              return formatTime(date);
            }}
          />
          <YAxis
            yAxisId="left"
            hide={false}
            domain={[(dataMin: number) => dataMin - dataMin * 0.1, (dataMax: number) => dataMax + dataMax * 0.1]}
            label={{ value: y1Measuring, angle: -90, position: 'insideLeft', dx: 0 }}
            ticks={ticks}
            tick={({ x, y, payload }) => (
              <text x={x} y={y} dy={4} textAnchor="end" fill="#ffffff">
                {payload.value}
              </text>
            )}
          />
          {hasY2 && <YAxis yAxisId="right" orientation="right" hide={false} domain={['auto', 'auto']} />}
          <Tooltip
            content={
              <CustomTooltip
                isMicroTooltip={isMicroTooltip}
                y1Measuring={y1Measuring}
                y1Colour={y1Colour}
                y2Colour={y2Colour}
              />
            }
            cursor={{ stroke: y1Colour, strokeDasharray: 2, fillOpacity: 0 }}
          />
          <Area
            type="linear"
            dataKey="y"
            stroke={y1Colour}
            fillOpacity={1}
            fill="url(#colorPv)"
            yAxisId="left"
            dot={{ stroke: 'white', strokeWidth: 1, r: 3, strokeDasharray: '' }}
          />{' '}
          {hasY2 && <Area type="linear" dataKey="y2" stroke={y2Colour} fillOpacity={0} yAxisId="right" />}
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

export default ChartAreaFull;
