import React from 'react';

interface CurrencyProps {
  value: number;
}

const Currency: React.FC<CurrencyProps> = ({ value }) => {
  const formatValue = (value: number): string => {
    if (value > 99) {
      return `$${Math.round(value)}`;
    } else if (value < 0.01) {
      return `$${value.toString()}`;
    } else {
      return `$${value.toFixed(2)}`;
    }
  };

  return <>{formatValue(value)}</>;
};

export default Currency;
