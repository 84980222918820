import React from 'react';
import { useMyContext } from '../../ContextProvider';
import { Timeframe } from '../../types/interfaces';

interface HeaderProps {
  title: string;
}

const Header: React.FC<HeaderProps> = ({ title }) => {
  const { timeframeSettings, updateTimeframeSettings } = useMyContext();
  // update the timeframe setting for this specific component
  const handleClick = (timeframe: Timeframe) => {
    updateTimeframeSettings(title, timeframe);
  };

  return (
    <div className="header-container">
      <h5>{title}</h5>
      {/* Render timeframe buttons only if this component has timeframe settings */}
      {timeframeSettings[title] && (
        <div className="timeframe-links">
          {/* Timeframe buttons - selected state based on current setting */}
          <button className={timeframeSettings[title] === 'week' ? 'selected' : ''} onClick={() => handleClick('week')}>
            Week
          </button>
          <button className={timeframeSettings[title] === 'month' ? 'selected' : ''} onClick={() => handleClick('month')}>
            Month
          </button>
          <button className={timeframeSettings[title] === '6month' ? 'selected' : ''} onClick={() => handleClick('6month')}>
            6 Months
          </button>
        </div>
      )}
    </div>
  );
};

export default Header;
