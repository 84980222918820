import React, { ReactElement } from 'react';
import Tooltip from '@mui/material/Tooltip';

interface CustomTooltipProps {
  value: string;
  altValue?: string;
  children?: ReactElement | null;
  minChars?: number;
}

const CustomTooltip: React.FC<CustomTooltipProps> = ({ value, altValue, minChars = 13, children }) => {
  const title = value ? value : altValue;

  return children ? (
    <Tooltip placement="top" title={title} enterDelay={900} arrow disableHoverListener={!!(title && title.length < minChars)}>
      {children}
    </Tooltip>
  ) : null;
};

export default CustomTooltip;
