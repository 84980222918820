export const componentTranslations = {
  CoinDay: {
    displayTitle: 'Coin of the Day',
  },
  Buzzwords: {
    displayTitle: 'Buzzwords',
  },
  // add your new component here:
  // NewComponent: {
  //   displayTitle: 'New Component Title',
  //   // ... other translations if needed
  // },
};
