import { useState } from 'react';

interface UseFullscreenCurtainReturn {
  state: { left: boolean; right: boolean };
  toggleDrawer: (anchor: 'left' | 'right', open: boolean) => (event: any) => void;
  closeAllDrawers: () => void;
  handleClose: (event: any, reason: any) => void;
}

const useFullscreenCurtain = (): UseFullscreenCurtainReturn => {
  const [state, setState] = useState<{ left: boolean; right: boolean }>({ left: false, right: false });

  const toggleDrawer = (anchor: 'left' | 'right', open: boolean) => (event: any) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const closeAllDrawers = () => {
    setState({ left: false, right: false });
  };

  const handleClose = (event: any, reason: any) => {
    if (reason === 'clickaway') {
      return;
    }

    closeAllDrawers();
  };

  return { state, toggleDrawer, closeAllDrawers, handleClose };
};

export default useFullscreenCurtain;
