import React, { useState } from 'react';

function PromotionReset() {
  const [promotionStatus, setPromotionStatus] = useState<boolean>(false);

  function handlePromotion() {
    localStorage.setItem("promotionStatus", '');
    setPromotionStatus(true);
    window.location.reload(); 
  }

  if (promotionStatus) {
    return null;
  }

  return (
    <div className="promotion-container-inert fade-in">
        <button onClick={handlePromotion}>SHOW PROMOTIONS</button>
    </div>
  );
}

export default PromotionReset;