import React from 'react';
import Header from '../../components/Header';

interface PanelProps {
  title: string;
  children: React.ReactNode;
}

const Panel: React.FC<PanelProps> = ({ title, children }) => {
  return (
    <div className="general-container">
      <Header title={title} />
      {children}
    </div>
  );
};

export default Panel;
