import React, { useState, useEffect } from 'react';
import Header from '../../components/Header';
import Vector from '../../components/Vector';
import Meter from '../../components/Meter';
import MeterTopValue from '../../components/MeterTopValue';
import Rollup from '../../components/Rollup';
import Accuracy from '../../components/Accuracy';
import ChartPosNegBar from '../../components/ChartPosNegBar';
import { sentimentCoords } from '../../data/testData';
import { useMyContext } from '../../ContextProvider';
import { Timeframe } from '../../types/interfaces';

export interface SentimentProps {
  averageConvictionPercentage: number;
}


function Sentiment({ title = 'Sentiment' }: { title?: string }) {
  const { timeframeSettings, updateTimeframeSettings } = useMyContext();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [sentimentData, setSentimentData] = useState<SentimentProps | null>(null);

  const getTimestamps = () => {
    const currentTimestamp = Math.floor(Date.now() / 1000);
    const oneMonthAgo = new Date();
    oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
    const oneMonthAgoTimestamp = Math.floor(oneMonthAgo.getTime() / 1000);

    return {
      currentTimestamp,
      oneMonthAgoTimestamp,
    };
  };

  useEffect(() => {
    const fetchTopTokens = async () => {
      setLoading(true);
      setError(null);

      const { currentTimestamp, oneMonthAgoTimestamp } = getTimestamps();

      try {
        const response = await fetch(
          process.env.REACT_APP_USE_REMOTE_API === 'true'
            ? `//${process.env.REACT_APP_REMOTE_API}:${process.env.REACT_APP_SERVER_PORT}/api/sentiment-conviction-series?timeframe=7&startDate=2024-10-01&endDate=2024-10-15`
            : `//${process.env.REACT_APP_LOCAL_API}:${process.env.REACT_APP_SERVER_PORT}/api/sentiment-conviction-series?timeframe=7&startDate=2024-10-01&endDate=2024-10-15`,
        );
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();

        setSentimentData(data);
      } catch (error) {
        console.error('Error fetching top tokens:', error);
        setError(error instanceof Error ? error.message : String(error));
      } finally {
        setLoading(false);
      }
    };

    fetchTopTokens();
  }, []);

  const handleClick = (timeframe: Timeframe) => {
    updateTimeframeSettings(title, timeframe);
  };

  return (
    <>
      <div className="general-container">

        <div className='header-container'>
          <Header title={'Market Sentiment'} />

          {/* {timeframeSettings[title] && ( */}
            <div className="timeframe-links">
              <button className={timeframeSettings[title] === 'week' ? 'selected' : ''} onClick={() => handleClick('week')}>
                Week
              </button>
              <button className={timeframeSettings[title] === 'month' ? 'selected' : ''} onClick={() => handleClick('month')}>
                Month
              </button>
              <button className={timeframeSettings[title] === '3month' ? 'selected' : ''} onClick={() => handleClick('3month')}>
                3 Months
              </button>
            </div>
          {/* )} */}
        </div>

      
        <div className="general-data-meter good">

        <div className='pretty-number-hero'>
          <small>Average Conviction Percentage</small>
          <h1>{sentimentData && sentimentData.averageConvictionPercentage}%</h1>
        </div>
          {/* <MeterTopValue good={4000} neutral={3000} bad={2000} title={title} /> */}
          <Vector good={4000} neutral={3000} bad={2000} />
          <Meter good={4000} neutral={3000} bad={2000} />
        </div>


        <div className="general-data-duo">
          <Rollup label="Videos Analyzed" value={273} />
          <Rollup label="Channels Analyzed" value={33} />
        </div>
        {/* <div className="general-data-transparent">
          <Accuracy label={'APRIL'} value={0} />
        </div> */}
        <div className="general-data">
          <label>HISTORICAL SENTIMENT</label>
          <ChartPosNegBar sentimentCoords={sentimentCoords} />
        </div>
        
      </div>
    </>
  );
}

export default Sentiment;