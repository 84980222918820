import RollupMacro from '../../components/RollupMacro';
import Header from '../../components/Header';
import ChartArea from '../../components/ChartArea';
import FeatureStat from '../../components/FeatureStat';

const coords = [
  { x: 1640995200, y: 0 },
  { x: 1641782400, y: 7 },
  { x: 1642569600, y: 12 },
  { x: 1643356800, y: 24 },
  { x: 1644144000, y: 6 },
  { x: 1644931200, y: 13 },
  { x: 1645718400, y: 8 },
  { x: 1646505600, y: 20 },
  { x: 1647292800, y: 12 },
  { x: 1648080000, y: 16 },
  { x: 1648867200, y: 20 },
  { x: 1649654400, y: 10 },
  { x: 1650441600, y: 8 },
  { x: 1651228800, y: 10 },
  { x: 1652016000, y: 12 },
  { x: 1652803200, y: 27 },
  { x: 1653590400, y: 20 },
  { x: 1654377600, y: 20 },
  { x: 1655164800, y: 33 },
  { x: 1655952000, y: 45 },
  { x: 1656739200, y: 70 },
];

function ViewsTrends() {
  return (
    <div className="general-container">
      <Header title={'Overall Crypto Video View Trends'} />

      <div className="general-data">
        <ChartArea
          y1Measuring={'avg. views'}
          height={72}
          data={coords}
          y1Colour={'#50B8E7'}
          marginTop={20}
          headerContent={
            <FeatureStat value={255000} valueChange={45} subtitle={'Average Views'} top={'-25px'} disableMoreSuffix={true} />
          }
        />
      </div>

      <div className="general-data-tri">
        <RollupMacro label="Week Average" value={220000} valueChange={45} />
        <RollupMacro label="Month Average" value={200000} valueChange={-5} />
        <RollupMacro label="6 Month Average" value={10000} valueChange={23} />
      </div>

      <div className="general-data">
        <ChartArea
          y1Measuring={'total views'}
          height={72}
          data={coords}
          y1Colour={'#50B8E7'}
          marginTop={20}
          headerContent={
            <FeatureStat value={23355000} valueChange={-10} subtitle={'Total Views'} top={'-25px'} disableMoreSuffix={true} />
          }
        />
      </div>
    </div>
  );
}

export default ViewsTrends;
