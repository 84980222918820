import React from 'react';
import TruncateString from '../../components/TruncateString';
import Tooltip from '@mui/material/Tooltip';
import CustomTooltip from '../CustomTooltip';
import { noLogoPath } from '../../config';

interface TitleProps {
  title: string;
  symbol?: string;
  subtitle?: string;
  label: string;
  effect: boolean;
  titleTruncate?: number;
  logo?: string;
  logoClass?: string;
  left?: string;
  tooltip?: string;
  bottomBumper?: boolean;
}

const Title: React.FC<TitleProps> = ({
  title,
  subtitle,
  effect,
  symbol,
  left,
  bottomBumper = false,
  titleTruncate = 20,
  logo,
  logoClass,
  tooltip,
}) => {
  return (
    <>
      <div
        key={title}
        className="title fade-in"
        style={{ marginLeft: left ? left : undefined, marginBottom: bottomBumper ? '1em' : undefined }}
      >
        {logo && logo !== '' ? (
          <img id="logo" src={logo} alt="" />
        ) : logoClass && logoClass !== '' ? (
          <i id="logo" className={`fa-solid ${logoClass}`}></i>
        ) : null}
        <CustomTooltip value={tooltip ? tooltip : title}>
          <h1 id={effect && (logo || logoClass) ? 'effect' : ''}>
            <TruncateString text={title} maxLength={titleTruncate} />
            {` `}
            <span>{symbol}</span>
            {subtitle && <small> {subtitle}</small>}
          </h1>
        </CustomTooltip>
      </div>
    </>
  );
};

export default Title;
