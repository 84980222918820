// FullscreenCurtains.tsx
import React from 'react';
import Drawer from '@mui/material/Drawer';
import Header from '../Header';
import Title from '../Title';
import { ListProps } from '../../types/interfaces';

interface FullscreenCurtainsProps {
  header: string;
  state: { left: boolean; right: boolean };
  handleClose: (event: any, reason: any) => void;
  closeAllDrawers: () => void;
  children: React.ReactNode[];
  selectedItem: ListProps;
}

const FullscreenCurtains: React.FC<FullscreenCurtainsProps> = ({
  selectedItem,
  header,
  state,
  handleClose,
  closeAllDrawers,
  children,
}) => {
  const childrenArray = React.Children.toArray(children);
  const capitalizedStr = (str: string) =>
    str
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');

  return (
    <>
      <Drawer anchor="left" id="expand" open={state.left} onClose={handleClose}>
        <div className="general-container-expanded fullscreen-curtains-left-pane">
          {/* <Header title={header} /> */}

          <Title
            label={''}
            effect={false}
            title={`${selectedItem.title.toUpperCase()}`}
            logo={selectedItem.logo}
            symbol={''}
            subtitle=""
            titleTruncate={70}
          />

          <div>{childrenArray[0]}</div>
        </div>
      </Drawer>

      <Drawer anchor="right" id="expand-side" open={state.left} onClose={handleClose} hideBackdrop>
        <div className="general-container-expanded fullscreen-curtains-right-pane">
          <i className="fa-sharp fa-light fa-xmark exit" onClick={closeAllDrawers}></i>
          <div className="list-fullscreen">{childrenArray[1]}</div>
        </div>
      </Drawer>
    </>
  );
};

export default FullscreenCurtains;
