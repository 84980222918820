import React, { useState, useEffect } from 'react';
import Header from '../../components/Header';
import Rollup from '../../components/Rollup';

export interface TopPicksProps {
  averageConvictionPercentage: number;
  projectName: string;
  tokenSymbol: string;
  chainName: string;
  logo: string;
  total_mentions: number;
  total_weighted_view_score: number;
  channelCount: number;
}

function TopPicks({ title = 'Top Picks' }: { title?: string }) {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [sentimentData, setSentimentData] = useState<TopPicksProps[] | null>(null);
  const [count, setCount] = useState(0);
  const [slideClass, setSlideClass] = useState('slide-down');

  const getTimestamps = () => {
    const formatDate = (date: Date): string => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    };

    const oneMonthAgo = new Date();
    oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);

    return {
      currentDate: formatDate(new Date()),
      oneMonthAgoDate: formatDate(oneMonthAgo),
    };
  };

  useEffect(() => {

    const fetchTopTokens = async () => {
      setLoading(true);
      setError(null);

      const { currentDate, oneMonthAgoDate } = getTimestamps();

      try {
        const response = await fetch(
          process.env.REACT_APP_USE_REMOTE_API === 'true'
            ? `//${process.env.REACT_APP_REMOTE_API}:${process.env.REACT_APP_SERVER_PORT}/api/top-tokens?interval=daily&start_date=${oneMonthAgoDate}&end_date=${currentDate}&category=meme`
            : `//${process.env.REACT_APP_LOCAL_API}:${process.env.REACT_APP_SERVER_PORT}/api/top-tokens?interval=daily&start_date=${oneMonthAgoDate}&end_date=${currentDate}&category=meme`,
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data: TopPicksProps[] = await response.json();

        setSentimentData(data);
      } catch (error) {
        console.error('Error fetching top tokens:', error);
        setError(error instanceof Error ? error.message : String(error));
      } finally {
        setLoading(false);
      }
    };

    fetchTopTokens();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCount((prevCount) => {
        if (sentimentData && prevCount < sentimentData.length - 1) {
          return prevCount + 1;
        } else {
          return 0; // Reset to the first item
        }
      });
    }, 2150);

    return () => clearInterval(interval);
  }, [sentimentData]);

  useEffect(() => {
    // Reset the class to trigger re-animation
    setSlideClass('');
    const timeout = setTimeout(() => {
      setSlideClass('slide-down');
    }, 50); // Small delay to allow the class to be removed and re-added

    return () => clearTimeout(timeout);
  }, [count]);

  // Helper function to replace '/thumb/' with '/standard/'
  const replaceThumbWithStandard = (url: string): string => {
    return url.replace('/thumb/', '/standard/');
  };

  return (
    <>
      <div className="meme-container">
        <div className='header-container'>
          <Header title={"TODAY'S MEMES"} />
        </div>

        {/* <div className="general-data"> */}
        <div className="ticker-wrap">
          <div className="ticker">
            {sentimentData && sentimentData.map((item, index) => (
              <div key={`original-${index}`} className="ticker__item">
                <img id="logo" src={replaceThumbWithStandard(item.logo)} alt="Logo" />
                <h1>{item.projectName}<br/><span>{item.tokenSymbol}</span></h1>
              </div>
            ))}
            {sentimentData && sentimentData.map((item, index) => (
              <div key={`duplicate-${index}`} className="ticker__item">
                <img id="logo" src={replaceThumbWithStandard(item.logo)} alt="Logo" />
                <h1>{item.projectName}<br/><span>{item.tokenSymbol}</span></h1>
              </div>
            ))}
          </div>
        </div>
        {/* </div> */}

        {sentimentData && sentimentData.length > 0 && (
          <>
            <div className="general-data">
              <div className='title'>
                <img id='logo' src={replaceThumbWithStandard(sentimentData[count].logo)} alt="Logo" />
                <h1 id='effect'>{sentimentData[count].projectName} <span>{sentimentData[count].tokenSymbol}</span></h1>
              </div>


            </div>

            <div className="general-data-tri">
                    <div className="rollup">
                      <label>CHAIN NAME</label>
                      <h2>
                        {sentimentData[count].chainName}
                      </h2>
                    </div>
                    <div className="rollup">
                      <label>MENTIONS</label>
                      <h2>
                        {sentimentData[count].total_mentions}
                      </h2>
                    </div>
                    <div className="rollup">
                      <label>CHANNEL COUNT</label>
                      <h2>
                        {sentimentData[count].channelCount}
                      </h2>
                    </div>
                  </div>

          </>
        )}
      </div>
    </>
  );
}

export default TopPicks;