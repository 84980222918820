import React from 'react';
import PrettyNumber from '../../components/PrettyNumber';
import NumberChange from '../../components/NumberChange';

interface RollupMicroProps {
  label: string;
  value: number;
  isPercentage?: boolean;
}

const RollupMicro: React.FC<RollupMicroProps> = ({ label, value, isPercentage }) => {
  return (
    <div className="rollup-micro">
      <label>{label}</label>
      {!!isPercentage ? (
        <h2>
          <NumberChange value={value} />
        </h2>
      ) : (
        <h2>
          <PrettyNumber value={value} />
        </h2>
      )}
    </div>
  );
};

export default RollupMicro;
